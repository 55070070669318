<template>
  <div v-if="!employmentRequired || (employmentLoaded && employmentComplete)" class="container margin-top-30">


    <payment-screen
        :isLogin="isLogin"
        :charityInfo="charityInfo"
        :inHonorConfig="donationConfig.donation.in_honor"
        :donationNoteConfig="donationConfig.donation.note"
        :coverFeesConfig="donationConfig.donation.cover_fees"
        :selectedCampaign="selectedCampaign"
        :allCampaigns="allCampaigns"
        v-on:back="$emit('back')"
    ></payment-screen>
  </div>


  <inner-layout v-else-if="employmentLoaded" :charity-info="charityInfo"
                :cover-photo="(selectedCampaign && selectedCampaign.id && selectedCampaign.image)?selectedCampaign.image:''"
  >
    <EmploymentForm
        v-if="employment"
        :charity-info="charityInfo"
        :employmentInitial="employment"
        v-on:saved="employmentSaved"
        :isLogin="isLogin"
        :is-required="donationConfig.harness_fields && donationConfig.harness_fields.employment && donationConfig.harness_fields.employment.enabled || false"
    ></EmploymentForm>
    <!-- Spacer -->
    <div class="margin-top-30"></div>
    <!-- Spacer / End-->
  </inner-layout>

</template>

<script>
  import InnerLayout from '../Parts/inner-layout.vue'
  import PaymentScreen from '../Donations/payment.vue'
  import employmentFormMixin from '../Donations/employment-form-mixin'

  export default {
    name: 'ProceedDonation',
    props: {
      isLogin: null,
      charityInfo: null,
      donorDetail: null,
      donationConfig: null,
      donationInfo: null,
      selectedCampaign: null,
      allCampaigns: null,
    },
    mixins: [employmentFormMixin],
    components: {
      PaymentScreen,
      InnerLayout
    },
    watch: {
      'donationInfo.isDone'() {
        if (this.donationInfo.isDone) {
          this.$emit('done');
        }
      }
    }
  }
</script>
