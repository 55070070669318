<template>
  <div >
    <inner-layout :charity-info="charityInfo"
                  :cover-photo="(selectedCampaign && selectedCampaign.id && selectedCampaign.image) ? selectedCampaign.image : ''"
    >
      <div class="login-register-page how-it-works">
        <!-- Welcome Text -->
        <div class="welcome-text btn-back-wrap">
          <a v-on:click.prevent="goBack" class="primary-color btn-back" href="#">
            <font-awesome-icon :icon="['far', 'chevron-left']"/>
          </a>
          <h3 style="font-size: 26px;">How it Works</h3>
          <span>Learn more about how Round-ups work</span>
        </div>

        <p><strong>1. Connect an Eligible Card</strong><br>
          Create a donor account and then link an eligible credit card that you'd like to use for round-up donations
        </p>
        <p><strong>2. Make Purchases with that Card</strong><br>
          Purchases made on that card will "Round-Up" to the next dollar as change which you can see in your donor account
        </p>
        <p><strong>3. Change is Donated Monthly</strong><br>
          "Round-Up" change from your card purchases accumulates and then is donated on the last day of the month
        </p>
        <!-- Form -->

        <!-- Button -->
        <button class="button full-width button-sliding-icon ripple-effect margin-top-10"
                v-on:click.prevent="startRoundUps"
                type="submit"
        >Continue <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></button>

        <!-- Powered by -->
        <div class="powered-by-separator">
          <span>Minimum monthly amount $9.99 -
            <a href="#"
               v-on:click.prevent="$refs.moreInfoModal.open();"
               target="_blank"
               class="learn-more">Learn More</a>
          </span>
        </div>

      </div>
      <!-- Spacer -->
      <div class="margin-top-30"></div>
      <!-- Spacer / End-->
    </inner-layout>
    <more-info-modal ref="moreInfoModal"
                     :charityInfo="charityInfo"
    ></more-info-modal>

  </div>

</template>

<script>

  import $ from 'jquery'
  import {mapState} from 'vuex'
  import InnerLayout from '../Parts/inner-layout.vue'
  import MoreInfoModal from '../Modals/more-info.vue'

  export default {
    name: 'CustomDonation',
    props: {
      isLogin: null,
      donorDetail: null,
      charityInfo: null,
      flowId: null,
      flowCampaigns: null,
      flowCampaignId: null,
      donationConfig: null,
      selectedCampaign: null
    },
    components: {
      InnerLayout,
      MoreInfoModal,
    },
    methods: {
      startRoundUps() {
        if (!this.isLogin) {
          this.$tracking.track(this.$tracking.EVENTS.SCREEN_TRANSITION, {
            'from': 'get_started',
            'to': 'login_register',
          });
          this.isStartRoundUps = true;
          this.$parent.$emit('open-login-modal', 'register');
          $('.register-tab').click();
        } else {

          this.$emit('done');
        }
      },
      goBack() {
        this.$emit('back')
      }
    },
    computed: {
      ...mapState({
        donationInfo: state => state.donation,
      }),
    },
    watch: {
      'isLogin'() {
        if (this.isStartRoundUps) {
          this.$emit('done');
        }
        // else {
        //   this.updateRoundUps();
        // }
      },
    },
    data() {
      return {
        isStartRoundUps: false,
      };
    },
  }
</script>

<style lang="scss">
.how-it-works{
  p{
    font-size: 15px;
    strong{
      font-size: 16px;
    }
  }
}
</style>
