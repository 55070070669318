<template>
  <inner-layout
      :cover-photo="(selectedCampaign && selectedCampaign.id && selectedCampaign.image)?selectedCampaign.image:''"
      :charity-info="charityInfo"
  >
    <div class="login-register-page custom-fields-wrap">

      <!-- Welcome Text -->
      <div class="welcome-text btn-back-wrap">

        <a v-on:click.prevent="$emit('back')" class="primary-color btn-back" href="#">
          <font-awesome-icon :icon="['far', 'chevron-left']"/>
        </a>

        <h3 v-if="donationInfo.type === DonationTypes.ROUNDUP">Round-Ups</h3>
        <h3 v-else style="font-size: 26px;">${{donationInfo.amount | formatMoney}}
          {{donationInfo.type === 'monthly' ? 'Monthly' : 'Donation'}}</h3>
        <span>Please provide the following information:</span>
      </div>

      <!-- Form -->
      <form @submit.prevent="saveData">


        <div v-for="field in donationConfig.custom_fields.fields"
             :key="field.id"
        >
          <div v-if="field.properties.type === 'select'">
            <h4 style="margin-bottom: 5px; font-weight:600;"
            >{{field.properties.name}}</h4>
            <div
                class="no-border margin-bottom-15"
                :title="field.properties.tooltip"
                v-tippy="{ placement : 'right', flipBehavior: ['right', 'top']}"
            >
              <bootstrap-select v-model="info[field.id]"
                                :required="field.required"
                                :options="field.properties.options"
                                data-live-search="true"
                                :title="field.properties.name"
                                :placeholder="field.properties.name"
                                trackBy="value"
                                label="name"
                                :disabled="isFormDisabled"
              ></bootstrap-select>
            </div>
          </div>

          <div v-else-if="field.properties.type === 'radio'">
            <h4 style="margin-bottom: 5px; font-weight:600;"
            ><span :title="field.properties.tooltip"
                   v-tippy="{ placement : 'right', flipBehavior: ['right', 'top']}">{{field.properties.name}}</span>
            </h4>
            <div class="no-border margin-bottom-15">
              <div v-for="(option, index) in field.properties.options"
                   :key="index"
              >
                <div class="radio" v-interactive-effect>
                  <input type="radio"
                         :selected="option.selected"
                         :value="option.value"
                         v-model="info[field.id]"
                         :name="`radio-input-${field.id}`"
                         :id="`radio-input-${field.id}-${index}`"
                         :required="field.required"
                         :disabled="isFormDisabled"
                  ><label :for="`radio-input-${field.id}-${index}`"><span class="radio-label"></span>
                  {{option.name}}</label>
                </div>
                <br>
              </div>
            </div>
          </div>

          <div v-else-if="field.properties.type === 'date'"
               class="no-border"
               :class="{'input-with-icon-left': field.properties.icon}">
            <i v-if="field.properties.icon"
               style="z-index: 100"
               :class="[field.properties.icon]"></i>
            <datepicker
                v-model="info[field.id]"
                :placeholder="field.properties.placeholder || field.properties.name || ''"
                :title="field.properties.tooltip"
                v-tippy="{ placement : 'right', flipBehavior: ['right', 'top']}"

                :required="field.required"
                :wrapper-class="field.properties.precision === 'm'?'hide-year-select':''"
                :minimum-view='getMinimumView(field.properties.precision)'
                :maximum-view='getMaximumView(field.properties.precision)'
                :format='getDateFormat(field.properties.precision)'
                :typeable="false"
                :disabled="isFormDisabled"
                v-dt-input
            ></datepicker>

          </div>

          <div v-else-if="field.properties.type === 'checkbox'" class="checkbox">

            <input type="checkbox"
                   :id="`chekcbox_${field.id}`"
                   v-model="info[field.id]"
                   :required="field.required"
                   :disabled="isFormDisabled"
            >

            <label :for="`chekcbox_${field.id}`"
                   :title="field.properties.tooltip"
                   v-tippy="{ placement : 'right', flipBehavior: ['right', 'top']}"
            ><span class="checkbox-icon"></span>{{field.properties.name}}</label>
          </div>

          <div v-else-if="field.properties.type === 'text' && field.properties.multiline">
            <textarea cols="30"
                      rows="3"
                      :maxlength="field.properties.max_length || 50000"
                      class="no-border"
                      :required="field.required"
                      v-model="info[field.id]"
                      :placeholder="field.properties.placeholder || field.properties.name || ''"
                      :title="field.properties.tooltip"
                      v-tippy="{ placement : 'right', flipBehavior: ['right', 'top']}"
                      :disabled="isFormDisabled"
            ></textarea>
          </div>

          <div v-else class="no-border" :class="{'input-with-icon-left': field.properties.icon}">
            <i v-if="field.properties.icon" :class="[field.properties.icon]"></i>
            <input type="text"
                   class="input-text no-border"
                   :maxlength="field.properties.max_length || 10000"
                   :placeholder="field.properties.placeholder || field.properties.name || ''"
                   v-model="info[field.id]"
                   :title="field.properties.tooltip"
                   v-tippy="{ placement : 'right', flipBehavior: ['right', 'top']}"
                   :required="field.required"
                   :disabled="isFormDisabled"
            />
          </div>
        </div>
        <!-- Button -->
        <button 
          :class="{ disabled: isFormDisabled }"
          :disabled="tryTo"
          class="button full-width button-sliding-icon ripple-effect margin-top-15"
                type="submit"
        >
          <font-awesome-icon v-if="tryTo" :icon="['fas', 'spinner']" spin size="1x"/>
          Continue 
          <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i>
        </button>

        <!-- Spot for error messages -->
        <slot name="below-button"></slot>
      </form>

      <footer-flow :donation-config="donationConfig"></footer-flow>

    </div>

  </inner-layout>
</template>

<script>
  import InnerLayout from '../Parts/inner-layout.vue'
  import Datepicker from 'vuejs-datepicker';
  import {submitCustomField} from "../../utils/api";
  import {mapMutations} from "vuex";
  import FooterFlow from './footer-flow.vue'
  import BootstrapSelect from '../BootstrapSelect'


  const moment = require('moment');

  export default {
    name: 'CustomFields',
    props: {
      isLogin: null,
      charityInfo: null,
      donorDetail: null,
      donationConfig: null,
      donationInfo: null,
      selectedCampaign: null,
      disabled: {
        type: Boolean,
        'default': false,
      },
    },
    components: {
      InnerLayout,
      Datepicker,
      FooterFlow,
      BootstrapSelect
    },
    directives: {
      'dt-input': {
        inserted(el) {
          const input = el.querySelector('input');
          input.removeAttribute('readonly');
          input.onkeydown = (e) => e.preventDefault();
        }
      },
    },
    created() {
      let selected = null;
      this.info = this.donationConfig.custom_fields.fields.reduce((res, item) => {

        switch (item.properties.type) {
          case "checkbox":
            res[item.id] = item.properties.selected || false;
            break;
          case "select":
          case "radio":
            res[item.id] = null;

            if (item.properties.options) {
              selected = item.properties.options.filter(i => i.selected);
              if (selected && selected.length) {
                res[item.id] = selected[0].value;
              }
            }
            break;
          default:
            res[item.id] = '';
            break;
        }

        return res;
      }, {});
    },
    methods: {
      ...mapMutations('donation', [
        'setCustomFieldsResponseToken',
      ]),
      getMinimumView(precision) {
        switch (precision) {
          case 'y':
            return 'year';
          case 'm':
          case 'y-m':
            return 'month';
        }
        return 'day';
      },
      getMaximumView(precision) {
        if (precision === 'm') {
          return 'month';
        }
        return 'year';
      },
      getDateFormat(precision) {
        switch (precision) {
          case 'y':
            return 'yyyy';
          case 'm':
            return 'MMMM';
          case 'y-m':
            return 'MMMM yyyy';
        }
        return 'MMMM d, yyyy';
      },
      saveData() {
        this.tryTo = true;

        submitCustomField(
          this.donationInfo.flow_id,
          {"responses": this.getResponses()},
          this.charityInfo.charity_id
        ).then(info => {
          this.setCustomFieldsResponseToken(info.response_token);
          this.$emit('done', info.response_token);
          this.tryTo = false;
        }).catch(err => {
          this.tryTo = false;
          alert(err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.')
        });
      },
      getResponses() {
        return Object.entries(this.info).map(([id, value]) => {
          id = Number(id);
          const filed = this.donationConfig.custom_fields.fields.find(filed => Number(filed.id) === id);
          if (filed.properties.type === 'date' && value) {
            switch (filed.properties.precision) {
              case 'y':
                value = moment(value).format('YYYY');
                break;
              case 'm':
                value = moment(value).format('MM');
                break;
              case 'y-m':
                value = moment(value).format('YYYY-MM');
                break;
              default:
                value = moment(value).format('YYYY-MM-DD');
                break;
            }
          } else if(filed.properties.type === 'date'){
            value = null
          }
          return {id, value};
        });
      }
    },
    computed: {
      isFormDisabled: function () {
        return this.tryTo || this.disabled || false;
      },
    },
    data() {
      return {
        info: {},
        date: null,
        tryTo: false
      };
    }
  }
</script>
