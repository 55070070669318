import { fundingSource } from "../../utils/api";
import config from '../../config.json';
import { showNotification } from "../../utils/utils";

export default {
  methods: {
    createStripe() {
      if (!this.stripe) {
        // eslint-disable-next-line
        this.stripe = Stripe(config.stripe.public_token, config.stripe.options);
      }
    },
    initStripe() {
      if (document.getElementById('card-element') && !this.stripeInitialized) {

        this.createStripe();

        const elements = this.stripe.elements();

        const style = {
          base: {
            color: '#1f314a',
            lineHeight: '18px',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: 'rgba(31, 49, 74, 0.3)'
            }
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        };

        this.cardObj = elements.create('card', {
          style: style,
          hidePostalCode: false
        });

        this.cardObj.mount('#card-element');

        this.cardObj.addEventListener('change', (event) => {
          if (event.error) {
            this.cardIsError = true;
            this.cardAddError = event.error.message;
          } else {
            this.cardIsError = false;
          }

          this.cardType = event.brand;
        });

        this.stripeInitialized = true;
      }
    },
    onVerifyInvisible(response) {
      this.captchaKey = response;
      this.paymentFormSubmit();
    },
    paymentFormSubmit() {

      if (this.captchaKey === '') {
        this.$refs.recaptcha.execute();
        return null;
      }

      this.isCardConnecting = true;
      this.cardIsError = false;
      this.cardAddError = '';

      this.stripe.createToken(this.cardObj).then((result) => {
        if (result.error) {
          // Inform the user if there was an error.
          this.cardIsError = true;
          this.cardAddError = result.error.message;
          this.isCardConnecting = false;
          this.$refs.recaptcha.reset();
          this.onExpired();
        } else {
          fundingSource.addFundingSource(result.token.id, this.captchaKey)
            .then(() => {

              this.$tracking.track(this.$tracking.EVENTS.ADD_PAYMENT_INFO, {
                'content_category': result.token.card && result.token.card.brand || 'Payment Card',
              });

              this.isCardConnecting = false;
              this.$refs.addPaymentModal.close();
              showNotification("Payment method has been added", this.charityInfo.primary_color);
              this.loadCards();

            })
            .catch(err => {
              this.$refs.recaptcha.reset();
              this.onExpired();
              const fullError = err.responseJSON && err.responseJSON.full_error || '';
              this.cardIsError = true;
              this.cardAddError = err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.';
              if (fullError) {
                this.cardAddError = `${this.cardAddError}. ${fullError}`;
              }
              this.isCardConnecting = false;
            });
        }
      });
    },
  },
  data() {
    return {
      isCardConnecting: false,
      cardIsError: '',
      cardAddError: '',
      stripe: null,
      stripeInitialized: false,
      recaptchaShow: false
    }
  }
}