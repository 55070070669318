<template>
  <div v-if="(!employmentRequired || (employmentLoaded && employmentComplete)) && step !== 'custom-fields'">
    <inner-layout :charity-info="charityInfo"
                  :cover-photo="(previewCampaign && previewCampaign.id && previewCampaign.image)?previewCampaign.image:''"
    >
      <div v-if="step==='done'"
           class="login-register-page">
        <!-- Welcome Text -->
        <div class="welcome-text">
          <h3 style="font-size: 26px;">You're all set!</h3>
          <span>You've successfully added Round-Ups!<br>Would you like to set a
              <a href="#"
                 v-on:click.prevent="$refs.moreInfoModal.open();"
                 class="popup-with-zoom-anim">Monthly Cap</a>?
            </span>
        </div>

        <!-- Account Type -->
        <div class="welcome-text">
          <div class="company-logo">
            <tick></tick>
          </div>
        </div>

        <div v-if="selectedAccount || selectedCardLinkAccount" class="margin-bottom-30">
          <div class="section-headline margin-bottom-15" style="padding-right: 0">
            <h5 style="font-weight:600;">Set a Monthly Cap:
              <sup>
                <i class="help-icon"
                   v-tippy="{ placement : 'top',  arrow: true, theme: 'dark'}"
                   title="A Monthly Cap allows you to set a limit on how much you donate in a single month in Round-Ups"
                >
                  <font-awesome-icon :icon="['fal', 'info-circle']"/>
                </i>

              </sup>
            </h5>
            <p style="padding-bottom:5px; ">Limit my Round-Ups to under <a href="#">${{ maxCap }}</a>/month</p>
          </div>

          <input class="bidding-slider"
                 type="text"
                 data-slider-handle="custom"
                 data-slider-currency="$"
                 data-slider-min="10"
                 data-slider-max="100"
                 data-slider-step="1"
                 :data-slider-value="maxCap"
                 data-slider-tooltip="hide"
          />

        </div>

        <!-- Button -->
        <button v-if="selectedAccount || selectedCardLinkAccount"
                class="button full-width button-sliding-icon ripple-effect margin-top-10"
                v-on:click.prevent="saveSettings"
                :class="{ disabled: tryTo }"
                :disabled="tryTo"
                type="submit">
          Submit <i>
          <font-awesome-icon :icon="['far', 'long-arrow-alt-right']"/>
        </i>
        </button>

        <!-- Powered by -->
        <div class="powered-by-separator"><span>⚡ Powered by <strong>Harness</strong></span></div>

      </div>

      <!-- CARD LINK CONFIRM STEP -->
      <div v-else-if="step === 'card-link-confirm'" class="login-register-page">
        <!-- Welcome Text -->
        <div class="welcome-text">
          <h3 style="font-size: 26px; margin-left:30px;">Final Step
            <sup>
              <i class="help-icon"
                 v-tippy="{ placement : 'top',  arrow: true, theme: 'dark'}"
                 title="Complete this final step to confirm you'd like to enroll in Round-Ups"
              >
                <font-awesome-icon :icon="['fal', 'info-circle']"/>
              </i>
            </sup>
          </h3>
          <span>Authorize Round-Ups on this Card<br><br></span>
        </div>


        <!-- Image Type -->
        <div class="welcome-text">
            <span class="company-logo">
              <img
                  :src="require('./bank-roundups.svg')"
                  width="205px" height="247px" alt="" style="max-width:50%; margin-top: -30px; margin-bottom: -10px">
            </span>
        </div>

        <p v-if="false" style="font-size: 12px; text-align: center;">
          <!--          [INSERT VISA LEGAL TEXT HERE]-->
        </p>

        <!-- Button -->
        <button class="button full-width button-sliding-icon ripple-effect margin-top-10"
                v-on:click.prevent="tryEnrollWithCardLink"
                type="submit"
                :class="{ 'disabled': tryTo }"
                :disabled="tryTo"
        >Enroll in Round-ups <i>
          <font-awesome-icon :icon="['far', 'fingerprint']"/>
        </i></button>

        <slide-up-down class="slide-up-down" :class="{'is-active': !!hasCardEnrollError}" :active="!!hasCardEnrollError">
          <div class="notification error margin-top-15 margin-bottom-15">
            <p>{{ cardEnrollError }}</p>
            <a href="#" v-on:click.prevent="hasCardEnrollError = false" class="close"></a>
          </div>
        </slide-up-down>

        <!-- Powered by -->
        <div class="powered-by-separator">
          <span>Minimum monthly amount of $9.99 -
            <a href="#"
               v-on:click.prevent="$refs.moreInfoModal.open();"
               target="_blank"
               class="learn-more">Learn More</a>
          </span>
        </div>
      </div>
      <!-- END CARD LINK CONFIRM STEP -->

      <!-- PLAID CONFIRM STEP -->
      <div v-else-if="step==='confirm'" class="login-register-page">
        <!-- Welcome Text -->
        <div class="welcome-text">
          <h3 style="font-size: 26px; margin-left:30px;">Final Step
            <sup>
              <i class="help-icon"
                 v-tippy="{ placement : 'top',  arrow: true, theme: 'dark'}"
                 title="Complete this final step to confirm with your card provider that you'd like to enroll in Round-Ups"
              >
                <font-awesome-icon :icon="['fal', 'info-circle']"/>
              </i>
            </sup>
          </h3>
          <span>Authorize Round-Ups on this Card<br><br>

            </span>
        </div>


        <!-- Image Type -->
        <div class="welcome-text">
            <span class="company-logo">
              <img
                  :src="require('./bank-roundups.svg')"
                  width="205px" height="247px" alt="" style="max-width:50%; margin-top: -30px; margin-bottom: -10px">
            </span>
        </div>

        <p style="font-size: 12px; text-align: center;">
          The next screen is a SECURE login with your card provider<br>
          We do NOT receive or store any of your account credentials
        </p>

        <!-- Button -->
        <button class="button full-width button-sliding-icon ripple-effect margin-top-10"
                v-on:click.prevent="openPlaid"
                type="submit"
        >Continue <i>
          <font-awesome-icon :icon="['far', 'fingerprint']"/>
        </i></button>

        <!-- Powered by -->
        <div class="powered-by-separator">
          <span>Minimum monthly amount of $9.99 -
            <a href="#"
               v-on:click.prevent="$refs.moreInfoModal.open();"
               target="_blank"
               class="learn-more">Learn More</a>
          </span>
        </div>

      </div>
      <!-- END PLAID CONFIRM STEP -->

      <attach-campaign v-else-if="step === 'attach-campaign'"
                       :charity-info="charityInfo"
                       :all-campaigns="allCampaigns"
                       :defaultCampaignId="campaignId"
                       :donationConfig="donationConfig"
                       v-on:set-campaign="setCampaign"
                       v-on:preview-campaign="setPreviewCampaign"
      ></attach-campaign>

      <div v-else class="login-register-page">
        <!-- Welcome Text -->
        <div class="welcome-text btn-back-wrap">
          <a v-on:click.prevent="goBack" class="primary-color btn-back" href="#">
            <font-awesome-icon :icon="['far', 'chevron-left']"/>
          </a>
          <h3 style="font-size: 26px;">Enroll an Eligible Card</h3>
          <span>To track and charge for Round-Ups</span>
        </div>

        <!-- Account Type -->
        <div class="margin-bottom-20">
          <div class="card card-rup bg-secondary">
            <div class="card-type">
              <img v-if="cardType && cardType!='unknown'" :src="getCardImage(cardType)">
            </div>

            <div v-if="donorDetail" class="cardholder">{{ donorDetail.first_name }}&nbsp;{{ donorDetail.last_name }}
            </div>
            <div class="card-digits">
              <span class="card-number">Debit/Credit Card</span>
              <!--<span>MM/YY</span>-->
            </div>
          </div>
        </div>

        <div v-if="isLoaded">
          <div v-if="notConnectedCards.length && this.selectCardId !== 'new'">

            <select class="default"
                    ref="selectAccount"
                    v-select-picker
                    :title="'Use a Saved Card'"
                    v-model="selectCardId"
            >
              <option v-for="card in notConnectedCards"
                      :key="card.funding_source_id"
                      :value="card.funding_source_id"
              >
                <font-awesome-icon class="margin-right-3"
                                   style="font-size: 17px"
                                   :icon="['fab', getFaName(card.metadata.brand)]"/>
                {{ card.metadata.brand }} {{ card.metadata.last4 }}
              </option>
              <option :value="'new'">
                <font-awesome-icon class="margin-right-4" :icon="['far', 'plus-circle']"/>
                Add a Card
              </option>
            </select>
          </div>

          <!-- Form DO THE STRIPE Elements Here INSTEAD -->
          <form v-else @submit.prevent="paymentFormSubmit">

            <vgs-form
                v-on:update-card-type="cardType = arguments[0]"
                class="margin-bottom-15"
                ref="vgsCardForm"
            ></vgs-form>

            <!-- Quick example for swapping back to the existing, saved cards -->
            <a href="#" v-if="notConnectedCards.length" @click="selectCardId = null">Use saved card</a>

            <slide-up-down class="slide-up-down" :class="{'is-active': !!cardIsError}" :active="!!cardIsError">
              <div class="notification error margin-bottom-15">
                <p>{{ cardAddError }}</p>
                <a href="#" v-on:click.prevent="cardIsError = false" class="close"></a>
              </div>
            </slide-up-down>


            <p v-if="false" style="font-size: 11px; line-height: 15px;">By entering my card information, I agree to the
              following: I
              authorize my payment card network to monitor and share my transaction data made with my registered payment
              card at all merchants with Harness and its third party service providers. Program shall obtain, provide,
              and/or use my transaction data to facilitate the Round-Up Program in accordance with Harness Terms of Use
              and Privacy Policy. You may opt-out of the Round-Up Program at any time -
              <router-link :to="{name: 'Terms'}" target="_blank"><b>Learn More</b></router-link>
            </p>

            <div class="checkbox checkbox-cc margin-top-5 margin-right-15">
              <input type="checkbox"
                     id="checkbox_cc_1"
                     required
              >
              <label for="checkbox_cc_1">
                <span class="checkbox-icon"></span>
                By entering my card information, I agree to the following: I authorize my payment card network to
                monitor and share my transaction data made with my registered payment card at all merchants with
                Harness and its third-party service providers. Program shall obtain, provide, and/or use my transaction
                data to facilitate and charge for the Round-Up Program in accordance with Harness
                <router-link :to="{name: 'Terms'}" target="_blank"><b>Terms of Use</b></router-link>
                and
                <router-link :to="{name: 'Privacy'}" target="_blank"><b>Privacy Policy</b></router-link>
                . You may opt-out of the Program at any time.
              </label>
            </div>
            <div class="checkbox checkbox-cc margin-top-5 margin-right-15">
              <input type="checkbox"
                     id="checkbox_cc_2"
                     required
              >
              <label for="checkbox_cc_2">
                <span class="checkbox-icon"></span> I authorize this card to be charged for Round-Ups
              </label>
            </div>


            <vue-recaptcha v-if="!cardId"
                           :sitekey="$root.config.google.recaptcha_key_invisible ? $root.config.google.recaptcha_key_invisible : '6Ld0WrsUAAAAAOawnDvZtuFRpPtwuV7ptlpxJJjW'"
                           class="margin-bottom-15"
                           :loadRecaptchaScript="true"
                           ref="recaptcha"
                           size="invisible"
                           @verify="onVerifyInvisible"
                           @expired="onExpired"
            ></vue-recaptcha>

            <button class="button full-width button-sliding-icon ripple-effect "
                    :disabled="isCardConnecting"
                    :class="{ disabled: isCardConnecting }"
                    type="submit"
            ><span v-if="isCardConnecting" class="loading-state">
                <font-awesome-icon :icon="['fas', 'spinner']" spin size="1x"/></span>
              Enroll Card <i>
                <font-awesome-icon :icon="['far', 'long-arrow-alt-right']"/>
              </i>
            </button>

          </form>
        </div>

        <!-- Powered by -->
        <div class="powered-by-separator">
          <span>Minimum monthly amount $9.99 -
             <a href="#"
                v-on:click.prevent="$refs.moreInfoModal.open();"
                target="_blank"
                class="learn-more">Learn More</a>

          </span>
        </div>

      </div>
    </inner-layout>
    <more-info-modal ref="moreInfoModal"
                     :charityInfo="charityInfo"
    ></more-info-modal>
    <magnific-popup-modal :show="false"
                          :config="$root.popupConfig"
                          ref="backModal"
                          :id="'small-dialog-1'"
                          :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
    >
      <div class="sign-in-form">

        <ul class="popup-tabs-nav">
          <li><a href="#tab1">Give Monthly</a></li>
        </ul>
        <div class="popup-tabs-container">
          <div class="popup-tab-content" id="tab">

            <div class="welcome-text">

              <img :src="require('./donate_monthly.svg')" alt="" style="max-width:50%; margin-bottom: 10px">
              <h3>Give Monthly Instead?</h3>
              <p class="margin-top-10">
                Not sure you're ready to donate Round-Ups?
                No problem! You can use the card you added to give monthly.</p>
            </div>

            <button class="margin-top-15 button full-width button-sliding-icon ripple-effect"
                    v-on:click="$router.push({name: 'Donate', query: {'selected-method': DonationTypes.MONTHLY, new: 'new'}})"
            >OK, let's do Monthly <i>
              <font-awesome-icon :icon="['far', 'long-arrow-alt-right']"/>
            </i></button>

            <div class="welcome-text margin-bottom-0 margin-top-10" style="font-size: 14px;">
              <a href="#" v-on:click.prevent="skipBackCheck = true;$router.go(-1)">No, thanks for asking though</a>
            </div>

          </div>
        </div>
      </div>
    </magnific-popup-modal>
  </div>

  <CustomFieldsFlow v-else-if="step === 'custom-fields'"
                    :isLogin="isLogin"
                    :donationConfig="donationConfig"
                    :donationInfo="donationInfo"
                    :charityInfo="charityInfo"
                    :donorDetail="donorDetail"
                    v-on:done="customFieldsDone"
                    :disabled="tryTo"
  >
    <template v-slot:below-button>
      <slide-up-down class="slide-up-down" :class="{'is-active': !!hasCardEnrollError}" :active="!!hasCardEnrollError">
        <div class="notification error margin-top-15 margin-bottom-15">
          <p>{{ cardEnrollError }}</p>
          <a href="#" v-on:click.prevent="hasCardEnrollError = false" class="close"></a>
        </div>
      </slide-up-down>
    </template>
  </CustomFieldsFlow>

  <div v-else-if="employmentLoaded">
    <inner-layout :charity-info="charityInfo"
                  :cover-photo="(previewCampaign && previewCampaign.id && previewCampaign.image) ? previewCampaign.image : ''"
    >
      <EmploymentForm
          v-if="employment"
          :charity-info="charityInfo"
          :employmentInitial="employment"
          v-on:saved="employmentSaved"
      ></EmploymentForm>
      <!-- Spacer -->
      <div class="margin-top-30"></div>
      <!-- Spacer / End-->
    </inner-layout>
  </div>

</template>

<script>
/* eslint-disable */
import $ from 'jquery'
import config from '../../config.json';
import cardMixin from './card-mixin';
import {roundupAccount, recurringDonation, fundingSource, getJwt, cardLinkRoundupAccount} from '../../utils/api'
import {setActiveAccount, getActiveAccount, interactiveEffect} from '../../utils/utils'
import MoreInfoModal from '../Modals/more-info.vue'
import MagnificPopupModal from '../Parts/MagnificPopupModal'
import CustomFieldsFlow from '../Flow/custom-fields-flow'
import InnerLayout from '../Parts/inner-layout.vue'
import AttachCampaign from './attach-campaign.vue'
import Tick from '../Parts/tick.vue'
import SlideUpDown from 'vue-slide-up-down'
import Snackbar from 'node-snackbar';
import VueRecaptcha from 'vue-recaptcha';
import recaptchaMixin from '../Donations/recaptcha-mixin'
import stripeDirectiveMixin from './stripe-directive-mixin'
import employmentFormMixin from './employment-form-mixin'
import {mapActions, mapState} from 'vuex'
import VgsForm from './vgs-form-2.vue';
import vgsMixin from './vgs-mixin';

export default {
  name: 'CustomDonation',
  props: {
    isLogin: null,
    donorDetail: null,
    charityInfo: null,
    flowId: null,
    flowCampaigns: null,
    flowCampaignId: null,
    donationConfig: null,
  },
  mixins: [cardMixin, recaptchaMixin, stripeDirectiveMixin, employmentFormMixin, vgsMixin],
  created() {
    document.addEventListener('mousemove', this.handlerMousemove);
    document.addEventListener('mouseleave', this.handlerMouseleave);
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.handlerMousemove);
    document.removeEventListener('mouseleave', this.handlerMouseleave);
  },
  beforeRouteLeave(to, from, next) {
    if (
        (to.name === 'Donate')
        && this.step === 'confirm' && !this.skipBackCheck
    ) {
      this.$refs.backModal.open();
      next(false);
    } else {
      next();
    }
  },
  components: {
    MoreInfoModal,
    SlideUpDown,
    MagnificPopupModal,
    InnerLayout,
    Tick,
    AttachCampaign,
    VueRecaptcha,
    CustomFieldsFlow,
    VgsForm,
  },
  mounted() {
    if (this.flowId && this.flowCampaignId) {
      this.campaignId = this.flowCampaignId;
    }
    if (this.$route.query && 'fix-account' in this.$route.query) {
      this.fixAccount = this.$route.query['fix-account'];

      let query = Object.assign({}, this.$route.query);
      delete query['fix-account'];
      this.$router.replace({query});
    }

    this.upCampaigns();

    Promise.all([fundingSource.getAllFundingSources(), roundupAccount.getAllRoundupAccounts()])
        .then(([cards, accounts]) => {
          this.walletCards = cards;
          this.roundupAccounts = accounts;
          this.isLoaded = true;

          if (this.fixAccount) {
            const accountToFix = this.roundupAccounts.reduce((res, account) => account.accountId === this.fixAccount ? account : res, null);
            if (accountToFix) {
              this.selectCard(accountToFix.fundingSourceId);
            }
          }
        });
  },
  methods: {
    goBack() {
      this.$emit('back')
    },
    async customFieldsDone() {
      if (this.cardData && this.cardData.isCardLinkCompatible) {
        try {
          if (this.tryTo) {
            return;
          }

          this.tryTo = true;
          await this.enrollWithCardLink();
        }
        catch (err) {
          this.cardEnrollError = err.responseJSON.error || 'An error has occurred, please try again.';
          this.hasCardEnrollError = true; 
        } 
        finally {
          this.tryTo = false;
        }
      } else {
        this.createAccount();
      }
    },
    selectCard(preSelectedCard) {
      // Get the whole funding source object
      const card = this.getPreExistingCardByFundingSourceId(preSelectedCard);

      this.cardId = preSelectedCard;

      // If the existing card was enrolled and vaulted through VGS,
      // then we can directly enroll it through card-link, rather than needing to use Plaid.
      if (card && card.is_card_link_compatible) {
        this.cardData = {
          id: card.id,
          fundingSourceId: card.funding_source_id,
          brand: card.metadata.brand,
          isCardLinkCompatible: card.is_card_link_compatible,
        };

        this.step = 'card-link-confirm';
        return;
      }

      this.step = 'confirm';
    },
    setPreviewCampaign(previewCampaign) {
      this.previewCampaign = previewCampaign;
    },
    handlerMousemove(e) {
      this.mouse.x = e.clientX;
      this.mouse.y = e.clientY;
    },
    handlerMouseleave() {
      if (this.step === 'confirm' && this.mouse.y < 100 && this.popupCounter < 1) {
        this.popupCounter++;
        this.$refs.backModal.open();
      }
    },
    ...mapActions('campaigns', [
      'updateCampaigns',
    ]),
    onVerifyInvisible(response) {
      this.captchaKey = response;
      this.paymentFormSubmit();
    },
    paymentFormSubmit() {
      const self = this;
      if (self.captchaKey === '') {
        this.$refs.recaptcha.execute();
        return null;
      }

      this.isCardConnecting = true;
      this.cardIsError = false;
      this.cardAddError = '';

      const saveFundingSource = this.$refs.vgsCardForm.saveCard(getJwt(), this.captchaKey);

      saveFundingSource
          .then((result) => {
            // const cardId = result.card.id;
            const fundingSourceId = result.card.fundingSourceId;
            const brand = result.card.brand;
            const isCardLinkCompatible = result.card.isCardLinkCompatible;

            try {
              self.$tracking.track(self.$tracking.EVENTS.ADD_PAYMENT_INFO, {
                'content_category': brand || 'Payment Card',
              });
            } catch (e) {
              console.warn(e);
            }

            self.isCardConnecting = false;
            self.cardId = fundingSourceId;
            self.cardData = result.card;

            self.step = isCardLinkCompatible ? 'card-link-confirm' : 'confirm';
          })
          .catch(err => {

            self.$refs.recaptcha.reset();
            self.onExpired();
            const fullError = err.responseJSON && err.responseJSON.full_error || '';
            self.cardIsError = true;
            self.cardAddError = err.responseJSON && err.responseJSON.error || err.error || 'An error has occurred, please try again.';
            if (fullError) {
              self.cardAddError = `${self.cardAddError}. ${fullError}`;
            }
            self.isCardConnecting = false;
          });
    },
    upCampaigns() {
      if (this.charityInfo) {
        this.updateCampaigns({charity_id: this.charityInfo.charity_id});
      }
    },
    setCampaign(campaignId) {
      this.campaignId = campaignId;
      this.customFieldsDone();
    },
    async tryEnrollWithCardLink() {
      this.hasCardEnrollError = false;
      this.cardEnrollError = '';

      this.tryTo = true
      const self = this;
      if (self.allCampaigns.length > 1) {
        if ('query' in self.$route && 'campaign_id' in self.$route.query) {
          self.setCampaign(self.$route.query.campaign_id);
        } else if (self.flowId && self.flowCampaigns.length === 1 && self.flowCampaignId) {
          self.setCampaign(self.flowCampaignId);
        } else {
          self.step = 'attach-campaign';
        }
      } else {
        try {
          await this.enrollWithCardLink();
        }
        catch (err) {
          this.cardEnrollError = err.responseJSON.error || 'An error has occurred, please try again.';
          this.hasCardEnrollError = true; 
        }
      }

      this.tryTo = false
    },
    /**
     * Create a new Card-Link roundup account
     */
    async enrollWithCardLink() {
      if (
          this.donationConfig.custom_fields && this.donationConfig.custom_fields.fields.length > 0
          && !this.donationInfo.custom_fields_response_token
      ) {
        this.step = 'custom-fields';
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(true);
          }, 100);
        });
      }
      // Enroll the selected card in card-linked roundups
      return cardLinkRoundupAccount.addRoundupAccount(
          this.cardData.id,
          this.campaignId,
          this.flowId,
          this.donationInfo.custom_fields_response_token || '',
      )
          .then((result) => {
            const roundupAccountId = result.enrollment.roundupAccountId;

            this.step = 'done';
            this.updateCardLinkRoundUpsSettings(roundupAccountId);
          });
    },
    updateCardLinkRoundUpsSettings(roundupAccountId) {
      const self = this;

      try {
        this.$confetti.start({canvasId: null});
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e.message);
      }

      setTimeout(() => {
        try {
          this.$confetti.stop()
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(e.message);
        }
      }, 3000);

      cardLinkRoundupAccount.getRoundupAccount(roundupAccountId).then(response => {

        const roundupAccount = response.roundupAccount;
        this.selectedCardLinkAccount = roundupAccount;

        this.maxCap = roundupAccount.maxCap;
        this.rounding = roundupAccount.rounding / 100;

        this.$nextTick(() => {
          $(".bidding-slider").slider()
              .on('slide', function (event) {
                self.maxCap = event.value;
              })
              .on('change', function (event) {
                self.maxCap = event.value.newValue;
              });
        });
      });
    },
    saveSettingsCardLink() {
      if (this.selectedCardLinkAccount) {
        cardLinkRoundupAccount.updateRoundupSettings(
            this.selectedCardLinkAccount.id,
            this.maxCap,
            Math.round(this.rounding * 100),
            this.selectedCardLinkAccount.campaignId,
            this.selectedCardLinkAccount.designationId,
        ).then(() => {

          Snackbar.show({
            text: 'Your monthly cap has been set!',
            pos: 'bottom-center',
            showAction: false,
            actionText: "Dismiss",
            duration: 3000,
            textColor: '#fff',
            backgroundColor: '#383838'
          });
          this.tryTo = false
          this.$router.push({name: 'AccountRoundUps', query: {card: this.selectedCardLinkAccount.id}});
        })
      }
    },
    createAccount() {
      if (
          this.donationConfig.custom_fields && this.donationConfig.custom_fields.fields.length > 0
          && !this.donationInfo.custom_fields_response_token
      ) {
        this.step = 'custom-fields';
        return;
      }

      const self = this;

      roundupAccount.addRoundupAccount(
          this.metadata.public_token,
          this.metadata.account_id,
          this.metadata.account.name,
          this.metadata.account.type,
          this.metadata.institution.institution_id,
          this.metadata.institution.name,
          this.metadata.account.mask,
          this.campaignId,
          this.fixAccount || false,
          this.flowId,
          this.donationInfo.custom_fields_response_token || '',
      )
          .then((accountRes) => {
            recurringDonation.setFundingSource(accountRes.account_id, self.cardId).then(() => {
              setActiveAccount(accountRes.account_id);

              if (self.fixAccount) {
                //delete old account
                roundupAccount.deleteRoundupAccount(self.fixAccount).then(() => {
                  self.step = 'done';
                  self.updateRoundUpsSettings();
                });
              } else {
                self.step = 'done';
                self.updateRoundUpsSettings();
              }
            })
          })
          .catch(err => {
            this.plaidError = err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.';
          });
    },
    openPlaid() {
      const self = this;
// eslint-disable-next-line
      const handler = Plaid.create({
        clientName: config.plaid.client_name,
        env: config.plaid.env,
        key: config.plaid.token,
        webhook: config.plaid.webhook,
        product: ['transactions'],
        apiVersion: 'v2',

        onSuccess: function (public_token, metadata) {
          self.$refs.backModal.close();
          // Send the public_token to your app server.
          // The metadata object contains info about the institution the
          // user selected and the account ID, if the Account Select view
          // is enabled.
          self.metadata = metadata;

          if (self.allCampaigns.length > 1) {
            if ('query' in self.$route && 'campaign_id' in self.$route.query) {
              self.setCampaign(self.$route.query.campaign_id);
            } else if (self.flowId && self.flowCampaigns.length === 1 && self.flowCampaignId) {
              self.setCampaign(self.flowCampaignId);
            } else {
              self.step = 'attach-campaign';
            }
          } else {
            self.createAccount();
          }
        },
        onExit: function (err) {
          self.$refs.backModal.close();
          // The user exited the Link flow.
          if (err != null) {
            // The user encountered a Plaid API error prior to exiting.
            self.plaidError = err.display_message || 'An error has occurred, please try again.';
          }
        },
      });

      handler.open();
    },
    updateRoundUpsSettings() {
      const self = this;

      try {
        this.$confetti.start({canvasId: null});
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e.message);
      }

      setTimeout(() => {
        try {
          this.$confetti.stop()
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(e.message);
        }
      }, 3000);

      roundupAccount.getAllRoundupAccounts().then(accounts => {
        const accountId = getActiveAccount();
        if (accountId) {
          this.selectedAccount = accounts.reduce((res, val) => val.accountId === accountId ? val : res, false);
          if (this.selectedAccount) {
            this.maxCap = this.selectedAccount.maxCap;
            this.multiplier = this.selectedAccount.multiplier;
            this.rounding = this.selectedAccount.rounding / 100;
          }
        }
        this.$nextTick(() => {
          $(".bidding-slider").slider()
              .on('slide', function (event) {
                self.maxCap = event.value;
              })
              .on('change', function (event) {
                self.maxCap = event.value.newValue;
              });
        });
      });
    },
    saveSettings() {
      this.tryTo = true

      if (!this.isCardLink) {
        this.saveSettingsLegacy();
      } else {
        this.saveSettingsCardLink();
      }
    },
    saveSettingsLegacy() {
      if (this.selectedAccount) {
        roundupAccount.updateRoundupSettings(
            this.selectedAccount.accountId, this.maxCap, this.multiplier, this.rounding * 100, false, 'old'
        ).then(() => {

          Snackbar.show({
            text: 'Your monthly cap has been set!',
            pos: 'bottom-center',
            showAction: false,
            actionText: "Dismiss",
            duration: 3000,
            textColor: '#fff',
            backgroundColor: '#383838'
          });
          this.tryTo = false
          this.$router.push({name: 'AccountRoundUps', query: {card: this.selectedAccount.accountId}});
        })
      }
    },

    /**
     * Search the list of existing funding sources and return the card with the matching Stripe `fundingSourceId`.
     *
     * @param {string} fundingSourceId A Stripe funding source ID
     * @returns {Object}
     */
    getPreExistingCardByFundingSourceId(fundingSourceId) {
      return this.walletCards.find((card) => {
        return card.funding_source_id == fundingSourceId;
      });
    }
  },
  watch: {
    'charityInfo'() {
      this.upCampaigns();
    },
    'selectCardId'() {
      if (this.selectCardId && this.selectCardId !== 'new') {
        this.selectCard(this.selectCardId);
      }
    },
    'notConnectedCards'() {
      if (!this.preSelectedCard && this.notConnectedCards.length) {
        this.preSelectedCard = this.notConnectedCards[0].funding_source_id;
      }
    },
    'step'(n, o) {
      const trackingName = (name) => {
        switch (name) {
          case 'connect-card':
            return 'connect_card';
          case 'confirm':
            return 'authorize_roundups';
          case 'attach-campaign':
            return 'campaign_designation';
          case 'custom-fields':
            return 'custom_fields';
          case 'done':
            return 'roundups_confirmation';
          default:
            return name;
        }
      }

      switch (this.step) {
        case 'custom-fields':
        case 'attach-campaign':
        case 'done':
        case 'confirm':
          this.$tracking.track(this.$tracking.EVENTS.SCREEN_TRANSITION, {
            'from': trackingName(o),
            'to': trackingName(n),
          });
          break;
      }
      if (this.step === 'done') {
        const params = {};

        if (this.campaignId) {
          params['campaign_id'] = this.campaignId;
        }

        if (this.donationInfo.flow_id) {
          params['checkout_id'] = this.donationInfo.flow_id;
        }

        this.$router.replace({name: 'RoundupsConfirmation', query: params});
      }
    },
  },
  computed: {
    ...mapState({
      donationInfo: state => state.donation,
    }),
    allCampaigns() {
      if (this.flowId) {
        return this.flowCampaigns;
      }
      return this.$store.getters['campaigns/allCampaignsWithDefault'](this.charityInfo);
    },
    notConnectedCards() {
      return this.walletCards.filter(card => {

        for (let i = 0; i < this.roundupAccounts.length; i++) {
          if (this.roundupAccounts[i].fundingSourceId === card.funding_source_id) {
            return false;
          }
          if (this.roundupAccounts[i].account_type === "card-linked"
              && card.id == this.roundupAccounts[i].fundingSourceId) {
            return false;
          }
        }

        interactiveEffect();
        return true;
      })
    },
    /**
     * Whether or not this donor is enrolling in a card-link program, vs legacy Plaid.
     *
     * @returns {bool} TRUE if card-link, FALSE if Plaid.
     */
    isCardLink() {
      return this.cardData && this.cardData.isCardLinkCompatible;
    }
  },
  data() {
    return {
      stripe: null,
      step: 'connect-card',//'confirm','done', 'custom-fields', 'card-link-confirm'
//        step: 'done',//testing
      cardType: '',
      cardIsError: false,
      cardAddError: '',
      cardId: '',
      cardData: null, // Contains card information from a new funding source
//        accountId: null,
      isCardConnecting: false,

      plaidError: '',
      metadata: false,

      // for displaying error message during card-link enrollment
      hasCardEnrollError: false,
      cardEnrollError: '',

      maxCap: 50,
      campaignId: false,
      selectedAccount: null,
      selectedCardLinkAccount: null,
      initMaxCap: 0,
      initMultiplier: 0,
      initRounding: 0,
      skipBackCheck: false,
      mouse: {x: 0, y: 0},
      popupCounter: 0,
      walletCards: [],
      roundupAccounts: [],
      preSelectedCard: null,
      previewCampaign: null,
      fixAccount: null,

      isLoaded: false,
      tryTo: false,
      selectCardId: 'new' // Default to showing the new card form; set to `null` to show existing card selection first.
    };
  },
}
</script>

<style lang="scss">
.checkbox.checkbox-cc {
  input {
    display: inline-block;
    opacity: 0;
    z-index: -1;
    height: 16px;
    width: 16px;
    position: absolute;
    appearance: auto;
  }

  label {
    font-size: 11px;
    line-height: 15px;
    .checkbox-icon{
      border: 1px solid #b4b4b4;
      height: 16px;
      width: 16px;
    }
    .checkbox-icon:after{
      top: 1px;
    }
  }
}
</style>
