<template>
  <!-- Powered by -->
  <div class="powered-by-separator">
      <span v-if="donationConfig &&
  donationConfig.donation &&
  donationConfig.donation.display &&
  donationConfig.donation.display.footer &&
  donationConfig.donation.display.footer.text"
      v-html="donationConfig.donation.display.footer.text"></span>

    <span v-else>⚡ Powered by
          <a href="https://harnessapp.com/"
             target="_blank"
             style="font-weight:bold; color:#677788;">Harness</a>
        </span>
  </div>
</template>

<script>
  export default {
    name: 'FooterFlow',
    props: {
      donationConfig: null,
    }
  }
</script>