<template>
  <magnific-popup-modal :config="$root.popupConfig"
                        ref="moreInfoModal"
                        :id="'sign-in-dialog'"
                        :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
  >

    <div class="sign-in-form">

      <ul class="popup-tabs-nav">
        <li><a href="#login">What are Round-Ups?</a></li>
        <li><a href="#register">FAQs</a></li>
      </ul>

      <div class="popup-tabs-container">

        <!-- Login -->
        <div class="popup-tab-content" id="login">

          <!-- Welcome Text -->
          <div class="welcome-text">
            <h3>Round-Ups</h3>
            <span>Donate your Spare Change from Everyday Purchases in 3 Steps</span>
          </div>

          <!-- Need Help Separator -->
          <div class="need-help-separator padding-bottom-10"><span>How it Works</span></div>
          <p><a href="#"><strong>1. Connect a Card</strong></a><br>First, connect your debit/credit card to your donor
            account
          </p>
          <p><a href="#"><strong>2. Make Purchases</strong></a><br>Card purchases will "Round-Up" to the next dollar as
            change
          </p>
          <p><a href="#"><strong>3. Change is Donated</strong></a><br>"Round-Up" change accumulates and is donated each
            month
          </p>
          <div class="social-login-buttons social-login-buttons-row">
            <button class="primary-login ripple-effect"
                    v-on:click.prevent="$refs.moreInfoModal.close();$router.push({name: 'Terms'})"
            >Terms of Services
            </button>
            <button v-if="charityInfo && charityInfo.facebook_page_id" class="primary-login ripple-effect"
                    v-on:click.prevent="$refs.moreInfoModal.close();$router.push({name: 'Privacy'})"
            >Privacy Policy
            </button>
          </div>
        </div>
        <!-- Register -->
        <div class="popup-tab-content" id="register">

          <!-- Welcome Text -->
          <div class="welcome-text">
            <h3>FREQUENTLY ASKED QUESTIONS</h3>
<!--            <span><b>Frequently Asked Questions</b></span>-->
          </div>

          <!-- FAQ Answers -->

          <p>
            <a href="#"><strong>How does rounding up work?</strong></a><br>

            When you link your eligible payment card and set your monthly giving level,
            {{charityName}} automatically “rounds up” your transactions to the next dollar amount and donates that
            spare change. For example, if you spend $17.83 at a restaurant on your linked debit card or
            credit card, {{charityName}} will add 17 cents to your purchase.
            The Round-Up Program runs in accordance with the following
            <router-link :to="{name: 'Terms'}" target="_blank">Terms of Service</router-link>.
          </p>

          <p>
            <a href="#"><strong>Is my donation tax-deductible?</strong></a><br>

            Yes! You will receive an automated receipt which will include the tax deductible language,
            after your donation is processed each month; please keep a copy for your records.
          </p>

          <p>
            <a href="#"><strong>Does my change get deducted immediately when I swipe my card?</strong></a><br>

            To ensure you can easily find and manage your monthly donations for tax purposes,
            {{charityName}} does not immediately deduct your spare change every time you swipe.
            Instead, we’ll tally up your change throughout the month and deduct
            it at the end of the month so you can easily track your donation.
          </p>

          <p>
            <a href="#"><strong>When is the minimum donation of $9.99 charged?</strong></a><br>

            A monthly minimum donation of $9.99 will be charged to your linked card if any of the following
            conditions are met: (1) If your monthly round-up change accumulation is less than $10.
            (2) If we lose our connection to your registered card. This can occur if the bank associated with
            your card updates its security settings or if you change your bank log-in information.
            For example, if you change your credit or debit card account password, and do not update
            your {{charityName}} Spare Change account, we will be unable to track the transactions
            you made using that card to round up your change.
          </p>

          <p>
            <a href="#"><strong>Do I have to do anything to make the round up happen?</strong></a><br>

            Nope! That’s the great thing about rounding up: our system will automatically round up all of your
            purchases on your connected cards when you swipe until you’ve reached your giving target for the month.
          </p>

          <p>
            <a href="#"><strong>Why do you need my banking information?</strong></a><br>

            In order to round up your spare change on certain payment cards, we simply need to know when you’ve
            swiped your card. {{charityName}} does not have access or the ability to view any of your transaction data.
            Your data is secured with our third-party program operator Harness, who does not use your personal data
            or information for any purposes other than verifying your transactions to accurately
            record the spare change you choose to give.
          </p>

          <p>
            <a href="#"><strong>I can’t find my bank to add my card.</strong></a><br>

            Certain eligible payment cards require you to confirm your account with the bank that issued your card.
            To ensure your bank data is kept secure, we integrate with financial institutions by using Plaid,
            the industry-leading bank integration service. We are restricted to working with the banks that
            Plaid has added to its service.
          </p>

          <p>
            <a href="#"><strong>Can I link multiple cards?</strong></a><br>

            Yes you can! There is no limit to how many debit cards or credit cards you would like to use
            if you want to support the {{charityName}} across multiple eligible linked cards.
          </p>

          <p>
            <a href="#"><strong>Can I link both debit cards and credit cards?</strong></a><br>

            Yes. You can link any eligible active debit or credit card you frequently use.
          </p>

          <p>
            <a href="#"><strong>Can I link both debit cards and credit cards?</strong></a><br>

            Yes. You can link any eligible active debit or credit card you frequently use.<br><br>
            Note: Not all transactions made with your registered card (including PIN-based transactions,
            payment through a third-party digital wallet or payment app) can be monitored for eligibility
            to earn rewards. Do not use a PIN when making purchases if you want the transaction to be monitored.
            See <router-link :to="{name: 'Terms'}" target="_blank">Terms and Conditions</router-link> for more details.

          </p>

          <p>
            <a href="#"><strong>Can I turn off the round-up feature on my card?</strong></a><br>

            If you would like to support {{charityName}} in another way instead of rounding up your purchases,
            follow these steps for any linked card:
            <ol>
              <li>In your donor dashboard, select your avatar in the top right</li>
              <li>Tap Round-Ups, then Tap Settings</li>
              <li>Tap the card you want to stop rounding up purchases on</li>
              <li>Select the Remove Card Icon</li>
              <li>Confirm that you would like to stop transaction monitoring and the Round-Up program on your
                card by selecting “Cancel Round-Ups”</li>
            </ol>
            <br>
              You may access historical transactions and/or re-register your Payment Card at any time by
            following the steps in your user profile.
          </p>

          <p>
            <a href="#"><strong>How do I reset my password?</strong></a><br>

            Use <router-link :to="{name: 'PasswordReset'}" target="_blank">this link</router-link>
            to reset your password
          </p>

          <p>
            <a href="#"><strong>How do you keep my information secure?</strong></a><br>

            The security of your information is of the highest concern. The system uses bank-grade security
            to handle all sensitive financial data. Online banking credentials are never stored on the system.
            All data is secured with SSL encryption and multi-factor authentication to ensure unwanted access
            is prevented.
          </p>

          <p>
            <a href="#"><strong>What is your privacy policy?</strong></a><br>

            Use <router-link :to="{name: 'Privacy'}" target="_blank">this link</router-link> to review our
            privacy policy.
          </p>

          <p>
            <a href="#"><strong>How do I access my tax-deductible year-end giving report?</strong></a><br>

            You can see your donation history by logging into your account and viewing your donation history.
            <a href="#" v-on:click.prevent="$parent.$parent.$emit('open-login-modal');">Click here</a>
            to login to your account.
          </p>

          <p>
            <a href="#"><strong>Why did some of my transactions not get Rounded-Up?</strong></a><br>

            Not all transactions made with your registered card (including PIN-based transactions, payment through
            a third-party digital wallet or payment app) can be monitored for eligibility to earn rewards.
            Do not use a PIN when making purchases if you want the transaction to be monitored. See
            <router-link :to="{name: 'Terms'}" target="_blank">Terms and Conditions</router-link> for more details.
          </p>



          <div class="social-login-buttons social-login-buttons-row">
            <button class="primary-login ripple-effect"
                    onclick="window.open('https://donor-support.drift.help/', '_blank')"><i
            ><font-awesome-icon :icon="['far', 'question-circle']" /></i> More Questions?
            </button>
            <button v-if="charityInfo && charityInfo.facebook_page_id" class="primary-login ripple-effect"
                    v-on:click.prevent="contactUs(charityInfo.facebook_page_id)">
              <i><font-awesome-icon :icon="['far', 'envelope']" /></i> Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>

  </magnific-popup-modal>
</template>

<script>
  import MagnificPopupModal from '../Parts/MagnificPopupModal'

  export default {
    name: 'ModalMoreInfo',
    props: ['charityInfo'],
    components: {
      MagnificPopupModal,
    },
    computed: {
      charityName(){
        return this.charityInfo ? this.charityInfo.charity_name : '';
      }
    },
    methods: {
      contactUs(fbId) {
        window.open(`https://messenger.com/t/${fbId}`, '_blank');
      },
      open () {
        this.$refs.moreInfoModal.open();
      },
    }
  }
</script>
