import {userAccount} from "../../utils/api";
import EmploymentForm from '../Profile/employment-form'

export default {
  created() {
    if (this.employmentRequired) {
      this.updateEmployment();
    }
  },
  components: {
    EmploymentForm
  },
  methods: {
    employmentSaved(employmentInfo) {
      this.employmentComplete = true;
      this.employment = employmentInfo;
    },
    updateEmployment() {
      userAccount.getEmployment()
        .then(employment => {
          employment.check_matching = parseInt(employment.check_matching);
          this.employment = employment;
          this.employmentLoaded = true;
          this.employmentComplete = true;
        })
        .catch(() => {
          this.employment = {employer_name: '', occupation: '', check_matching: 1};
          this.employmentLoaded = true;
          this.employmentComplete = false;
        });
    },
  },
  computed: {
    employmentRequired() {
      return this.isLogin
        && this.donationConfig
        && this.donationConfig.harness_fields
        && this.donationConfig.harness_fields.employment
        && this.donationConfig.harness_fields.employment.enabled || false;
    },
  },
  data(){
    return{
      employment: null,
      employmentLoaded: false,
      employmentComplete: false,
    }
  }
}