<template>
  <div>
    <div v-if="inHonorConfig.enabled" class="checkbox margin-top-5 margin-right-15">
      <input type="checkbox"
             id="inhonor"
             :checked="donationInfo.isHonor"
             v-on:click.prevent="$refs.memoryModal.open();"
      >
      <label for="inhonor">
        <span class="checkbox-icon"></span> I'd like to give
        <a href="#" v-on:click.prevent="$refs.memoryModal.open();">in honor/memory</a>
      </label>
    </div>

    <div v-if="donationNoteConfig.enabled" class="checkbox margin-top-5">
      <input type="checkbox"
             id="note"
             :checked="donationInfo.note"
             v-on:click.prevent="$refs.donationNote.open(donationInfo.note, donationInfo.note_is_public)">
      <label for="note"><span class="checkbox-icon"></span> Add a donation <a
          v-on:click.prevent="$refs.donationNote.open(donationInfo.note, donationInfo.note_is_public)"
          href="#">note/comment</a>
      </label>
    </div>

    <magnific-popup-modal :show="false"
                          :config="$root.popupConfig"
                          ref="memoryModal"
                          v-on:open="memoryModalOpen"
                          :id="'sign-in-dialog'"
                          :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
    >
      <div class="sign-in-form">
        <ul class="popup-tabs-nav">
          <li><a href="#tribute" id="tribute-tab">Tribute</a></li>
          <li v-if="inHonorConfig.delivery_methods.length>0"><a :class="{'link-disabled': !isCompletedForm}"
                                                                href="#method" id="method-tab">Method</a></li>
          <li v-if="inHonorConfig.delivery_methods.length>0 && honor_method !== null"
          ><a :class="{'link-disabled': !isCompletedForm}" href="#delivery" id="delivery-tab">Delivery</a></li>
        </ul>
        <div class="popup-tabs-container">

          <!-- tribute tab -->
          <div class="popup-tab-content" id="tribute">

            <!-- Welcome Text -->
            <div class="welcome-text margin-bottom-15">
              <h3>In Honor/Memory</h3>
              <span>Tell us about who you'd like to honor</span>
            </div>

            <div class="text-center margin-bottom-15">
              <div class="radio margin-right-20">
                <input id="radio-honor"
                       name="honor-type"
                       type="radio"
                       v-model="honor_type"
                       value="honor">
                <label style="margin: 0" for="radio-honor"><span class="radio-label"></span>
                  In Honor
                </label>
              </div>
              <div class="radio">
                <input id="radio-memory"
                       name="honor-type"
                       type="radio"
                       v-model="honor_type"
                       value="memory">
                <label style="margin: 0" for="radio-memory"><span class="radio-label"></span>
                  In Memory
                </label>
              </div>
            </div>

            <form method="post"
                  v-on:submit.prevent="inHonorConfig.delivery_methods.length>0 ? switchModalTab('#method-tab') : honorSubmit()">
              <div class="input-with-icon-left">
                <i><font-awesome-icon :icon="['fal', 'id-card']"/></i>
                <input type="text"
                       class="input-text with-border"
                       name="honoreename-register"
                       placeholder="Honoree's Full Name"
                       v-model="honor_name"
                       required/>
              </div>

              <div class="input-with-icon-left">
                <i><font-awesome-icon :icon="['fal', 'user-circle']"/></i>
                <input type="text"
                       class="input-text with-border"
                       name="fromname-register"
                       placeholder="Your Full Name"
                       v-model="honor_from"
                       required/>
              </div>
              <textarea name="textarea"
                        cols="10"
                        placeholder="Write a Message..."
                        class="with-border"
                        :required="inHonorConfig.message && inHonorConfig.message.required"
                        v-model="honor_message"
              ></textarea>

              <button class="button full-width button-sliding-icon ripple-effect margin-top-10"
                      type="submit"
              >Save & Continue
                <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i>
              </button>
            </form>

          </div>
          <div class="popup-tab-content" id="method">

            <!-- Welcome Text -->
            <div class="welcome-text">
              <h3>Delivery Method</h3>
              <span>Would you like to share this Tribute</span>

              <span class="company-logo"
              ><img
                  v-if="$refs.memoryModal && $refs.memoryModal.visible"
                  :src="require('../Pages/images/invite-friends-new.png')"
                  alt=""
                  style="margin-top: -10px;"></span>
            </div>
            <!-- Button -->
            <button v-if="inHonorConfig.delivery_methods.includes('email')"
                    class="margin-top-10 button button-sliding-icon full-width ripple-effect"
                    v-on:click.prevent="setMethod('email')"
            >Send an Email
              <i><font-awesome-icon :icon="['far', 'at']"/></i>
            </button>
            <button v-if="inHonorConfig.delivery_methods.includes('letter')"
                    class="margin-top-10 button button-sliding-icon full-width ripple-effect"
                    v-on:click.prevent="setMethod('letter')"
            >Mail a Letter <i><font-awesome-icon :icon="['far', 'envelope']" /></i>
            </button>

            <!-- Delivery Method -->
            <div class="social-login-separator"><span>or</span></div>
            <div class="welcome-text margin-bottom-0">
              <a href="#"
                 v-on:click.prevent="honorSubmit"
                 class="margin-top-10"
                 style="font-size: 14px;">Continue without sharing this Tribute</a>
            </div>

          </div>
          <div class="popup-tab-content" id="delivery">

            <!-- Welcome Text -->
            <div v-if="honor_method === 'letter'" class="welcome-text">
              <h3>Mailing Address</h3>
              <span>Add details for this tribute letter</span>

            </div>
            <div v-else-if="honor_method === 'email'" class="welcome-text">
              <h3>Email Address</h3>
              <span>Add details for this tribute email</span>
            </div>
            <!--Form-->
            <form v-if="honor_method === 'email'"
                  :id="'honor-address-form'"
                  v-on:submit.prevent="honorSubmit"
            >
              <div class="input-with-icon-left">
                <i><font-awesome-icon :icon="['far', 'envelope']" /></i>
                <input type="email"
                       class="input-text with-border"
                       placeholder="Email of Recipient"
                       v-model="honor_email"
                       required/>
              </div>

            </form>

            <form v-else-if="honor_method === 'letter'"
                  :id="'honor-address-form'"
                  v-on:submit.prevent="honorSubmit"
            >
              <div class="input-with-icon-left">
                <i><font-awesome-icon :icon="['far', 'map-marker-alt']" /></i>
                <vue-google-autocomplete
                    id="recipient-address"
                    class="input-text with-border"
                    autocomplete="new-password"
                    aria-autocomplete="none"
                    autofill="off"
                    placeholder="Tribute Recipient’s Address"
                    country="us"
                    v-on:placechanged="getAddressData"
                ></vue-google-autocomplete>

              </div>

              <textarea name="textarea"
                        cols="10"
                        placeholder="Formatted address"
                        class="with-border margin-bottom-20"
                        v-model="honor_address"
                        required
              ></textarea>
            </form>

            <!-- Button -->
            <button class="margin-top-10 button button-sliding-icon full-width ripple-effect"
                    type="submit"
                    :form="'honor-address-form'"
            >Finish & Continue <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></button>

          </div>
        </div>
      </div>

    </magnific-popup-modal>

    <DonationNote ref="donationNote"
                  v-on:save-note="donationNoteSubmit"
    ></DonationNote>

  </div>
</template>

<script>
  import $ from 'jquery'
  import {mapState, mapMutations} from 'vuex'
  import MagnificPopupModal from '../Parts/MagnificPopupModal'
  import DonationNote from './donation-note'
  import {initTabs} from '../../utils/utils';
  import VueGoogleAutocomplete from 'vue-google-autocomplete'

  export default {
    name: 'HonorHone',
    props: {
      inHonorConfig: null,
      donationNoteConfig: null,
    },
    components: {
      MagnificPopupModal,
      VueGoogleAutocomplete,
      DonationNote
    },
    methods: {
      ...mapMutations('donation', [
        'honor',
        'setNote',
      ]),
      getAddressData(addressData) {
        this.honor_address = `${this.honor_name}\n${addressData.street_number} ${addressData.route}\n${addressData.locality}, ${addressData.administrative_area_level_1} ${addressData.postal_code}`;
      },
      setMethod(method) {
        this.honor_method = method;

        if (this.honor_method) {
          this.$nextTick(() => {
            initTabs();
            this.switchModalTab('#delivery-tab');
          });
        }
      },
      honorSubmit() {
        this.$refs.memoryModal.close();
        this.saveHonorInfo();
      },
      saveHonorInfo() {
        let honorInfo = {
          name: this.honor_name,
          from: this.honor_from,
          message: this.honor_message,
          type: this.honor_type,
        };

        if (this.honor_method) {
          honorInfo['method'] = this.honor_method;

          if (this.honor_method === 'email') {
            honorInfo['email'] = this.honor_email;
          } else {
            honorInfo['address'] = this.honor_address;
          }
        }

        this.honor(honorInfo);
      },
      switchModalTab(selector) {
        $(selector).click();
      },
      memoryModalOpen() {
        if (this.donationInfo.honorInfo) {
          this.honor_name = this.donationInfo.honorInfo.name;
          this.honor_from = this.donationInfo.honorInfo.from;
          this.honor_message = this.donationInfo.honorInfo.message;
          this.honor_type = this.donationInfo.honorInfo.type;

          this.honor_email = this.donationInfo.honorInfo.email;
          this.honor_address = this.donationInfo.honorInfo.address;
          this.honor_method = this.donationInfo.honorInfo.method;
        }
        this.$nextTick(() => {
          initTabs();
        });
        this.switchModalTab('#tribute-tab');
      },
      donationNoteSubmit(note, is_public) {
        this.setNote({
          note,
          is_public
        });
      },
    },
    computed: {
      ...mapState({
        donationInfo: state => state.donation,
      }),
      isCompletedForm() {
        return this.honor_name !== '' && this.honor_from !== '';
        //&& this.honor_message !== '';
      }
    },
    data() {
      return {
        honor_name: '',
        honor_from: '',
        honor_message: '',
        honor_type: 'honor',

        honor_email: '',
        honor_address: '',
        honor_method: null,//letter|email
      };
    }
  }
</script>