<template>
  <div class="login-register-page">
    <!-- Welcome Text -->
    <div class="welcome-text">
      <h3 style="font-size: 26px;">Designate Round-Ups</h3>
      <span>Donate your Round-Ups to a Campaign<br>
        <a v-if="!(donationConfig && donationConfig.donation && donationConfig.donation.campaigns.selection_required)"
            href="#" v-on:click.prevent="$emit('set-campaign', 0)">Skip for Now</a>
      </span>
    </div>

    <form v-on:submit.prevent="onSubmit">
      <h4 style="margin-bottom:10px; font-weight:600;">Choose a Campaign:</h4>

      <bootstrap-select
          class="campaign-select no-border"
          track-by="id"
          label="name"
          data-live-search="true"
          v-model="selectedCampaignId"
          :required="donationConfig && donationConfig.donation && donationConfig.donation.campaigns.selection_required"
          :options="allCampaigns"
      ></bootstrap-select>

      <button 
        class="button full-width button-sliding-icon ripple-effect margin-top-15"
        type="submit"
        :class="{ disabled: tryTo }"
        :disabled="tryTo"
      >
        <font-awesome-icon v-if="tryTo" :icon="['fas', 'spinner']" spin size="1x"/>
        Continue 
        <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i>
      </button>

    </form>
  </div>
</template>

<script>
  import BootstrapSelect from "@/components/BootstrapSelect";

  export default {
    name: 'AttachCampaign',
    props: {
      charityInfo: null,
      allCampaigns: null,
      defaultCampaignId: null,
      donationConfig: null,
    },
    components: {
      BootstrapSelect
    },
    mounted() {
      if(this.defaultCampaignId){
        this.selectedCampaignId = this.defaultCampaignId;
      }
    },
    computed: {
      selectedCampaign() {
        return this.allCampaigns.reduce((res, cmp) => parseInt(cmp.id) === parseInt(this.selectedCampaignId) ? cmp : res, null)
      }
    },
    methods: {
      onSubmit() {
        this.tryTo = true;
        this.$emit('set-campaign', this.selectedCampaignId);
      }
    },
    watch: {
      'selectedCampaignId'() {
        this.$emit('preview-campaign', this.selectedCampaignId ? this.selectedCampaign : null);
      },
    },
    data() {
      return {
        selectedCampaignId: 0,
        tryTo: false
      }
    }
  }
</script>