<template>
  <div class="row">
    <div class="col-xl-8 col-lg-8 content-right-offset">

      <div class="btn-back-wrap padding-left-40">
       <h3 style="font-size: 26px;"><strong>Payment Info</strong></h3>
        <p>How you would like to pay for this donation</p>
        <a v-on:click.prevent="$emit('back')" class="primary-color btn-back" href="#">
          <font-awesome-icon :icon="['far', 'chevron-left']"/>
        </a>

      </div>

      <form v-on:submit.prevent="createToken">
        <div class="payment margin-top-20">
          <div class="payment-tab"
               v-for="(source, index) in fundingSources"
               :key="index"
               :class="{'payment-tab-active': selectedFundingSource===source.funding_source_id}"
          >
            <div class="payment-tab-trigger">
              <input type="radio"
                     name="cardType"
                     :id="`creditCard${index}`"
                     v-model="selectedFundingSource"
                     :value="source.funding_source_id"
              />
              <label :for="`creditCard${index}`">{{source.metadata.brand}} {{source.metadata.last4}}</label>
              <img class="payment-logo" :src="getCardImage(source.metadata.brand)" alt="">
            </div>
          </div>

          <div class="payment-tab"
               :class="{'payment-tab-active': selectedFundingSource===''}">
            <div class="payment-tab-trigger">
              <input type="radio"
                     name="cardType"
                     v-model="selectedFundingSource"
                     id="creditCart"
                     :value="''"
              >
              <label for="creditCart">Add a Card</label>
              <img class="payment-logo" src="https://i.imgur.com/X8CqQw3.png" alt="">
            </div>

            <div class="payment-tab-content">

              <div>
                <!-- Begin Card collection form -->

                <!-- If the user is logged in, show the VGS card collection form -->
                <div v-if="!isGuestDonation">
                  <vgs-form
                    class="margin-bottom-15"
                    ref="vgsCardForm"
                    ></vgs-form>
                </div>
                <!-- Otherwise, if donating as a guest, show the Stripe Elements form -->
                <div v-else>
                   <div v-init-stripe class="margin-bottom-15"></div>
                </div>
                <!-- End card collection form -->

                <slide-up-down class="slide-up-down"
                               :class="{'is-active': !!cardIsError || isError}"
                               :active="!!cardIsError || isError">
                  <div class="notification error margin-bottom-15">
                    <p>{{cardIsError ? cardAddError : errorMessage}}</p>
                    <a href="#" v-on:click.prevent="cardIsError = false" class="close"></a>
                  </div>
                </slide-up-down>
              </div>

            </div>
          </div>

        </div>
        <div class="row margin-left-0 margin-right-0">
          <div v-if="coverFeesConfig.enabled" class="checkbox margin-top-20">
            <input type="checkbox" id="checkIsCover" v-model="isCoverFee">
            <label for="checkIsCover" style="font-size: 14px;">
              <span class="checkbox-icon"></span> I'd like to cover the
              <a class="primary-color-force fw-600"
                 :title="'Add $' + $options.filters.formatMoney(processingFees) + ' to help cover processing fees. Otherwise, we\'ll only receive $' + $options.filters.formatMoney(donationAmount-processingFees) + ' of your gift'"
                 v-tippy="{ placement : 'top',  arrow: true}"
              >${{processingFees | formatMoney}}</a> processing fees</label>

            <slide-up-down class="slide-up-down" :class="{'is-active': !isCoverFee}" :active="!isCoverFee">
              <div class="notification impact"
                   style="font-size: 14px"
              >By adding <b>${{processingFees | formatMoney}}</b> to cover processing fees, you'll help us receive
                100% of your intended gift
              </div>
            </slide-up-down>
          </div>
          <div v-else class="margin-top-10"></div>
        </div>

        <vue-recaptcha v-if="!selectedFundingSource"
                       :sitekey="$root.config.google.recaptcha_key_invisible ? $root.config.google.recaptcha_key_invisible : '6Ld0WrsUAAAAAOawnDvZtuFRpPtwuV7ptlpxJJjW'"
                       class="margin-bottom-15"
                       :loadRecaptchaScript="true"
                       ref="recaptcha"
                       size="invisible"
                       @verify="onVerifyInvisible"
                       @expired="onExpired"
        ></vue-recaptcha>

        <button class="button full-width button-sliding-icon ripple-effect margin-top-10 margin-bottom-20"
                type="submit"
                :class="{disabled: isPaymentProcess}"
                :disabled="isPaymentProcess"
        ><span v-if="isPaymentProcess" class="loading-state">
          <font-awesome-icon :icon="['fas', 'spinner']" spin size="1x"/></span>
          <span v-if="donationType === 'monthly'"
          >Give ${{totalAmount | formatMoney}} / month  <i><font-awesome-icon :icon="['far', 'repeat']"/></i></span>
          <span v-else>Donate ${{totalAmount | formatMoney}} <i><font-awesome-icon :icon="['far', 'check-circle']"/></i></span>
        </button>
      </form>
    </div>
    <!-- Summary -->
    <div class="col-xl-4 col-lg-4 margin-top-0 margin-bottom-15">

      <!-- Summary -->
      <div class="boxed-widget summary margin-top-0 margin-bottom-20">
        <div class="boxed-widget-headline">
          <div v-if="allCampaignsArr && allCampaignsArr.length && selectedCampaign">
            <h5 class="fw-600">Campaign:</h5>
            <div v-if="isEditCampaign">
              <bootstrap-select
                  class="campaign-select no-border"
                  v-model="selectedCampaignId"
                  :options="allCampaigns"
                  data-live-search="true"
                  trackBy="id"
                  label="name"
              ></bootstrap-select>
            </div>
            <div v-else>
              <h5 v-if="allCampaigns.length>1"
                  style="font-weight: 600; float: right">
                <a href="#" v-on:click.prevent="selectedCampaignId = selectedCampaign.id;isEditCampaign = true">Edit</a>
              </h5>
              <h3 style="font-size: 18px;">{{selectedCampaign.name}}</h3>
            </div>
          </div>
          <h3 v-else>Donation Summary</h3>
        </div>

        <div class="boxed-widget-inner">
          <ul>
            <li>
              {{donationType === 'monthly' ? 'Monthly Amount' : 'Donation Amount'}}<span>${{donationAmount | formatMoney(2)}}</span>
            </li>
            <li v-if="isCoverFee">Fees Covered <span>+ ${{processingFees | formatMoney(2)}}</span></li>
            <li class="total-costs">Total Donation <span>${{totalAmount | formatMoney(2)}}</span></li>
          </ul>
        </div>
      </div>
      <!-- Summary / End -->

      <honor-note :type="donationType"
                  :inHonorConfig="inHonorConfig"
                  :donationNoteConfig="donationNoteConfig"
      ></honor-note>
      <!-- Summary / End -->


    </div>
  </div>
</template>

<script>
  import stripeDirectiveMixin from './stripe-directive-mixin'
  import stripeMixin from './stripe-mixin';
  import cardMixin from './card-mixin';
  import {fundingSource, getJwt} from '../../utils/api'
  import SlideUpDown from 'vue-slide-up-down'
  import HonorNote from './honor-note.vue'
  import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
  import VueRecaptcha from 'vue-recaptcha';
  import recaptchaMixin from '../Donations/recaptcha-mixin'
  import BootstrapSelect from "../BootstrapSelect";
  import VgsForm from './vgs-form-2.vue';

  export default {
    name: 'Payment',
    props: {
      isLogin: null,
      charityInfo: null,
      inHonorConfig: null,
      donationNoteConfig: null,
      coverFeesConfig: null,
      selectedCampaign: null,
      allCampaigns: null,
    },
    components: {
      SlideUpDown,
      HonorNote,
      VueRecaptcha,
      BootstrapSelect,
      VgsForm,
    },
    mixins: [cardMixin, recaptchaMixin, stripeMixin, stripeDirectiveMixin],
    created() {
      this.isCoverFee = this.coverFeesConfig.default;
    },
    mounted() {
      // search by: "elements variant", use one input directive instead of:
      // this.stripe = Stripe(config.stripe.public_token);
      // this.initStripeElements();

      if (this.isLogin) {
        fundingSource.getAllFundingSources().then(sources => {
          if (sources.length && !this.selectedFundingSource) {
            this.selectedFundingSource = sources[0].funding_source_id;
          }
          this.fundingSources = sources;
          if (!this.selectedFundingSource) {
            this.selectedFundingSource = '';
          }
        });
      } else {
        this.selectedFundingSource = '';
      }
      //
    },
    methods: {
      ...mapActions('donation', [
        'donate',
      ]),
      ...mapMutations('donation', [
        'setCampaignId',
      ]),
      onVerifyInvisible(response) {
        this.captchaKey = response;
        this.createToken();
      },
      createToken() {
        this.isPaymentProcess = true;
        if (this.selectedFundingSource) {
          this.donate({
            funding_source_id: this.selectedFundingSource,
            charity_id: this.charityInfo.charity_id,
            is_cover_fee: this.isCoverFee
          })
            .catch(err => {
              this.isError = true;
              this.errorMessage = err;
            })
            .finally(() => this.isPaymentProcess = false);
        } else {
          if (this.captchaKey === '') {
            this.$refs.recaptcha.execute();
            return;
          }

          // Create `this.stripe` if it hasn't been initialized already.
          this.createStripe();

          if (this.isGuestDonation) {
            this.submitGuestDonation();
          }
          else if (this.isLogin) {
            const saveFundingSource = this.$refs.vgsCardForm.saveCard(getJwt(), this.captchaKey);

            saveFundingSource
              .then((result) => {
                const fundingSourceId = result.card.fundingSourceId;

                this.$tracking.track(this.$tracking.EVENTS.ADD_PAYMENT_INFO, {
                  'content_category': result.card && result.card.brand || 'Payment Card',
                });

                this.donate({
                  funding_source_id: fundingSourceId,
                  charity_id: this.charityInfo.charity_id,
                  is_cover_fee: this.isCoverFee
                })
                  .catch(err => {
                    this.$refs.recaptcha.reset();
                    this.onExpired();
                    this.isError = true;
                    this.errorMessage = err;
                  });
              })
              .catch((err) => {
                this.$refs.recaptcha.reset();
                this.onExpired();
                let errorMsg = err.responseJSON && err.responseJSON.error.message || err.error || 'An error has occurred, please try again.';
                this.isError = true;
                this.errorMessage = errorMsg;
                this.isPaymentProcess = false;
              });
          }
        }
      },
      /**
       * Submit a guest donation using Stripe directly, rather than through VGS.
       */
      submitGuestDonation() {
        const self = this;

        // Note, if we ever want to swap back to using VGS for guest donations,
        // we can do that by simply changing:
        //   `this.stripe.createToken(this.cardObj)`
        // to:
        //   `this.$refs.vgsCardForm.submitAndGetToken()`

        // Create a Stripe token for the card
        this.stripe.createToken(this.cardObj)
          .then(function (result) {

            if (result.token) {
              self.donate({
                stripe_token: result.token.id,
                charity_id: self.charityInfo.charity_id,
                is_cover_fee: self.isCoverFee,
                captchaKey: self.captchaKey
              })
                .catch(err => {
                  self.$refs.recaptcha.reset();
                  self.onExpired();
                  self.isError = true;
                  self.errorMessage = err;
                })
                .finally(() => self.isPaymentProcess = false);
            } else if (result.error) {
              self.isPaymentProcess = false;
              self.isError = true;
              self.errorMessage = 'message' in result.error ? result.error.message : 'An error has occurred, please check your card try again.';
            }
          })
          .catch((err) => {
            self.$refs.recaptcha.reset();
            self.onExpired();
            let errorMsg = err.responseJSON && err.responseJSON.error.message || 'An error has occurred, please try again.';
            self.isError = true;
            self.errorMessage = errorMsg;
            self.isPaymentProcess = false;
          });
      }
    },
    computed: {
      ...mapState({
        donationAmount: state => state.donation.amount,
        donationType: state => state.donation.type,
        allCampaignsArr: state => state.campaigns.allCampaigns,
      }),
      ...mapGetters('donation', [
        'processingFees',
      ]),
      totalAmount() {
        if (!this.isCoverFee) {
          return this.donationAmount;
        }
        return Number(this.donationAmount) + Number(this.processingFees);
      },
      /**
       * Return whether this donation is being made as a guest, and not a logged-in donor.
       *
       * @returns {bool}
       */
      isGuestDonation() {
        return this.donationType === 'guest';
      }
    },
    watch: {
      'selectedCampaignId'() {
        if (this.selectedCampaignId !== this.selectedCampaign.id) {
          this.setCampaignId(this.selectedCampaignId);
        }
      },
    },
    // selectedCampaignId
    data() {
      return {
        isPaymentProcess: false,

        fundingSources: [],
        stripe: null,
        isError: false,
        errorMessage: '',
        savedErrors: {},
        elements: [],

        isCoverFee: true,
        selectedFundingSource: false,

        isEditCampaign: false,
        selectedCampaignId: null,

        //one input form:
        cardObj: null,
        cardType: '',
        cardIsError: false,
        cardAddError: '',
      };
    }
  }
</script>
