<template>
  <inner-layout
      :cover-photo="(selectedCampaign && selectedCampaign.id && selectedCampaign.image)?selectedCampaign.image:''"
      :charity-info="charityInfo"
  >

    <div class="login-register-page">
      <!-- Welcome Text -->
      <div class="welcome-text btn-back-wrap">

        <a v-on:click.prevent="$emit('back')" class="primary-color btn-back" href="#">
          <font-awesome-icon :icon="['far', 'chevron-left']"/>
        </a>

        <h3 style="font-size: 26px;">Support Monthly</h3>
        <span>Turn that <a href="#"
                           class="popup-with-zoom-anim"
                           v-on:click.prevent="$refs.moreInfoModal.open()"
        ><b>${{donationInfo.amount | formatMoney}}</b></a> into a smaller monthly gift</span>
      </div>

      <!-- Cover Image -->
      <div class="welcome-text margin-top-0">
            <span class="company-logo">
              <img :src="donationConfig.donation.smart_ask.image_url || require('../Donations/monthly_ask11.png')"
                   style="border-radius: 4px"
                   alt="">
            </span>
      </div>

      <div class="welcome-text margin-bottom-15">
        <h4 style="font-size: 18px;"><strong>Giving less helps more:</strong></h4>
        <span>Lowering your donation to just
          <a href="#" v-on:click.prevent=""><b>${{amountMonthlyOption | formatMoney}}</b></a>/month<br>
          will increase your impact by over <a href="#"
                                               v-on:click.prevent=""><b>{{impactFactor}}x</b></a> this year</span>
      </div>

      <!-- Button -->
      <button class="button full-width button-sliding-icon ripple-effect margin-top-10 primary-bg"
              v-on:click.prevent="setTypePure('monthly');setDonationAmount({amount: amountMonthlyOption, charity_id: charityInfo.charity_id, type: 'monthly'});$emit('done')"
      >Donate <sup>$</sup>{{amountMonthlyOption | formatMoney}} / month
        <i><font-awesome-icon :icon="['far', 'repeat']"/></i>
      </button>

      <!-- Continue with current selection option -->
      <div class="welcome-text" style="margin-top:10px; font-size: 14px;">
        <a href="#"
           v-on:click.prevent="monthlyAnswer();$emit('done')"
        >Continue with a ${{donationInfo.amount | formatMoney}} donation</a>
      </div>
    </div>
  </inner-layout>
</template>

<script>
  import InnerLayout from '../Parts/inner-layout.vue'
  import {mapActions, mapMutations} from 'vuex'

  export default {
    name: 'ProceedDonation',
    props: {
      isLogin: null,
      charityInfo: null,
      donorDetail: null,
      donationConfig: null,
      donationInfo: null,
      selectedCampaign: null,
    },
    components: {
      InnerLayout,
    },
    methods: {
      ...mapActions('donation', [
        'addNote',
        'setDonationAmount',
      ]),
      ...mapMutations('donation', [
        'setTypePure',
        'monthlyAnswer',
      ]),
    },
    computed: {
      impactFactor() {
        return Math.round((this.amountMonthlyOption * 12 / this.donationInfo.amount) * 10) / 10;
      },
      amountMonthlyOption() {
        return Math.ceil(this.donationInfo.amount * this.donationConfig.donation.smart_ask.impact_multiplier / 12);
      },
    },
  }
</script>