<template>

  <inner-layout
      ref="innerLayout"
      :charity-info="charityInfo"
      :cover-photo="(selectedCampaign && selectedCampaign.id && selectedCampaign.image)?selectedCampaign.image:''"
      :preview-campaign="selectedCampaign"
  >
    <template v-slot:default="slotProps">
    <div class="login-register-page" >
      <!-- Welcome Text -->
      <div v-if="donationInfo.type === DonationTypes.ROUNDUP" class="welcome-text">
        <h3 style="font-size: 26px;">Give Change</h3>

        <span v-if="roundUpAccounts && roundUpAccounts.length">View your
            <router-link :to="{name: 'AccountRoundUps'}">"Round-Up"</router-link>
             Change
          </span>
        <span v-else>Donate your "Round-Up" Change</span>
      </div>
      <div v-else class="welcome-text">
        <h3 style="font-size: 26px;">{{(donationInfo.type === 'monthly') ? 'Give Monthly' : 'Make a Donation'}}</h3>

        <span v-if="donationImpactsFiltered && donationImpactsFiltered.length">
                Need a suggested giving amount?
                <a href="#" v-on:click.prevent="showSuggest('impact-amount-'+donationImpactsFiltered[0].amount)">Click Here</a>
              </span>
        <span v-else-if="!isLogin">Already have an account?
          <a href="#"
             v-on:click.prevent="$parent.$emit('open-login-only-modal')"
             class="popup-with-zoom-anim">Login</a>
        </span>
        <span v-else-if="donorDetail">
          Welcome back {{donorDetail.first_name}}!
          <a href="#" v-on:click.prevent="$parent.$emit('logout')">Not you?</a>
        </span>
      </div>
      <!-- Account Type -->
      <div
          v-if="donationConfig.donation.allowed_methods && donationConfig.donation.allowed_methods.length > 1"
          class="account-type">

        <div v-if="donationConfig.donation.allowed_methods.indexOf(DonationTypes.ONE_TIME)!==-1">
          <input type="radio"
                 id="onetime-radio"
                 class="account-type-radio"
                 :checked="donationInfo.type === DonationTypes.ONE_TIME"
          />
          <label v-on:click="updateType(DonationTypes.ONE_TIME)"
                 for="onetime-radio"
                 v-ripple-dark
          >One-Time</label>
        </div>

        <div v-if="donationConfig.donation.allowed_methods.indexOf(DonationTypes.MONTHLY)!==-1">
          <input type="radio"
                 id="monthly-radio"
                 class="account-type-radio"
                 :checked="donationInfo.type === DonationTypes.MONTHLY"
          />

          <label
              v-on:click="updateType(DonationTypes.MONTHLY)"
              for="monthly-radio"
              v-ripple-dark
          >Monthly</label>
        </div>

        <div v-if="donationConfig.donation.allowed_methods.indexOf(DonationTypes.ROUNDUP)!==-1">
          <input type="radio"
                 id="roundups-radio"
                 class="account-type-radio"
                 :checked="donationInfo.type === DonationTypes.ROUNDUP"
          />
          <label v-on:click="updateType(DonationTypes.ROUNDUP)"
                 for="roundups-radio"
                 v-ripple-dark
          >Round-Ups</label>
        </div>
      </div>

      <div v-if="donationInfo.type === DonationTypes.ROUNDUP"
           v-coin-animation
           class="roundups-wrap">
        <div class="heart sidetoside-animate"></div>
        <div class="welcome-text welcome-text-roundups">
            <span class="company-logo">
              <img :src="require('../Donations/cashier_background.png')" style="max-width: 100%" alt=""></span>
          <p v-if="roundUpAccounts && roundUpAccounts.length" class="margin-top-15">
            Thanks for connecting a card to donate your <br> Round-Up change -
            <router-link :to="{name: 'AccountRoundUps'}"><b>View Round-Ups</b></router-link>
          </p>
          <p v-else class="margin-top-15">
            Connect a card to round-up your purchases to<br/>the next dollar and donate your change
          </p>

        </div>

        <button class="button full-width button-sliding-icon ripple-effect"
                v-on:click.prevent="startRoundUps"
                type="submit"
        >{{roundUpAccounts && roundUpAccounts.length ? 'Add a Card' : 'Get Started'}} <i
        ><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i>
        </button>
      </div>
      <div v-else>
        <form method="post" @submit.prevent="proceedDonation">
          <transition v-if="amountSuggest.custom_amount.enabled"
                      name="show-donation-amount"
                      v-on:after-leave="isAnimation = false"
                      v-on:before-leave="isAnimation = true">
            <div v-if="amount_suggest===''"
                 class="input-with-icon-left input-donation-amount-wrap no-border">
              <i class="secondary-bg"
                 style="color: white"><font-awesome-icon :icon="['far', 'dollar-sign']" size="lg"/></i>
              <input type="number"
                     class="input-text no-border margin-bottom-15"
                     name="donation-amount"
                     id="donation-amount"
                     ref="amount"
                     v-model="amount"
                     :min="amountSuggest.custom_amount.min_cents/100"
                     :max="amountSuggest.custom_amount.max_cents/100"
                     step="0.01"
                     placeholder="50"
                     style="font-size: 20px;"
                     required
              />

              <a href="#" class="close-btn" v-on:click.prevent="updateAmountSuggest">
                <font-awesome-icon class="primary-color" :icon="['fas', 'times-circle']" size="1x"/>
              </a>
            </div>
          </transition>

          <div v-if="amount_suggest!=='' && !isAnimation" class="account-type account-type-label">

            <div v-for="(sAmount, index) in suggested"
                 :key="index"
            >
              <input type="radio"
                     name="amount"
                     :id="`amount${sAmount}-radio`"
                     class="account-type-radio"
                     v-model="amount_suggest"
                     :value="sAmount"
              />
              <label :for="`amount${sAmount}-radio`" v-ripple-dark> ${{sAmount | formatMoney}}</label>
            </div>
            <div v-if="amountSuggest.custom_amount.enabled">
              <input type="radio"
                     name="amount"
                     id="amount-custom-radio"
                     class="account-type-radio"
                     v-model="amount_suggest"
                     value=""
              />
              <label for="amount-custom-radio" v-ripple-dark> $____</label>
            </div>
          </div>

          <slide-up-down class="slide-up-down" :class="{'is-active': amountImpact}" :active="!!amountImpact">
            <div v-if="amount && amountImpact" class="notification impact">
              <strong>${{amount | formatMoney}} = {{amountImpact.name}}</strong>
              <p v-if="amountImpact">{{amountImpact.description}}</p>
            </div>
          </slide-up-down>

          <div v-if="allCampaignsArr.length > 0 && allCampaigns.length > 0 " class="relative">

            <h4 style="margin-bottom: 5px; font-weight:600;">Choose a Campaign:</h4>

            <a v-if="!slotProps.isDesktop2 && selectedCampaignId"
               href="#"
               v-on:click.prevent="$refs.innerLayout.showCampaignInfo()"
               class="right-top"

            ><small>View Campaign</small></a>
            <div class="margin-bottom-15">
              <bootstrap-select
                  class="campaign-select no-border"
                  data-size="6"
                  v-model="selectedCampaignId"
                  :options="sortedCampaigns"
                  :required="donationConfig.donation.campaigns.selection_required"
                  data-live-search="true"
                  trackBy="id"
                  label="name"
                  title="Choose a Campaign..."
              ></bootstrap-select>
            </div>
          </div>

          <div v-if="allDesignations.length > 0 && designationsLastUpdate">
            <h4 style="margin-bottom: 5px; font-weight:600;">Designate your Donation:</h4>
            <div class="margin-bottom-15">
              <bootstrap-select
                  class="campaign-select no-border"
                  v-model="selectedDesignationId"
                  :options="allDesignations"
                  :required="donationConfig.donation.designations.selection_required"
                  data-live-search="true"
                  data-size="4"
                  trackBy="id"
                  label="name"
                  title="Choose one of the following..."
              ></bootstrap-select>
            </div>
          </div>

          <honor-note
              :inHonorConfig="donationConfig.donation.in_honor"
              :donationNoteConfig="donationConfig.donation.note"
          ></honor-note>

          <button class="button full-width button-sliding-icon ripple-effect margin-top-10"
                  type="submit">
            Donate <template v-if="amount">${{amount | formatMoney}}</template>
            <small v-if="donationInfo.type === 'monthly'">/month</small>
            <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></button>
        </form>
      </div>

      <footer-flow :donation-config="donationConfig"></footer-flow>

    </div>

    <magnific-popup-modal
        :id="'small-dialog-1'"
        :show="false"
        :config="$root.popupConfig"
        ref="donationSuggestModal"
        :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
    >
      <!--Tabs -->
      <div class="sign-in-form">

        <ul class="popup-tabs-nav">
          <li v-for="(impact, index) in donationImpactsFiltered"
              :key="index">
            <a :href="`#impact-amount-${impact.amount}`">${{impact.amount / 100}}</a>
          </li>
        </ul>

        <div class="popup-tabs-container">

          <div v-for="(impact, index) in donationImpactsFiltered"
               :key="index" class="popup-tab-content" :id="`impact-amount-${impact.amount}`">

            <div class="welcome-text margin-bottom-10">
              <img v-if="impact.image && ($refs.donationSuggestModal && $refs.donationSuggestModal.visible)"
                   :src="impact.image"
                   alt="" class="margin-bottom-10" style="border-radius: 4px;">
              <span><strong class="secondary-color">${{impact.amount / 100}} Donation</strong></span>
              <h3>{{impact.name}}</h3>
            </div>

            <p class="text-center" style="font-size: 14px;">{{impact.description}}</p>
            <div class="social-login-buttons social-login-buttons-row">


              <button
                  v-if="checkImpactType(DonationTypes.ONE_TIME, impact.id) && donationConfig.donation.allowed_methods.indexOf(DonationTypes.ONE_TIME) !== -1"
                  class="primary-login ripple-effect margin-top-0"
                  :class="{'max-width-100': (donationConfig.donation.allowed_methods.indexOf(DonationTypes.ONE_TIME) === -1)}"
                  v-on:click.prevent="useSuggestion(impact.amount / 100, DonationTypes.ONE_TIME)"
              >Donate ${{impact.amount / 100}}
              </button>

              <button v-if="checkImpactType(DonationTypes.MONTHLY, impact.id) && donationConfig.donation.allowed_methods.indexOf('monthly') !== -1"
                      class="primary-login ripple-effect margin-top-0"
                      :class="{'max-width-100': (donationConfig.donation.allowed_methods.indexOf('monthly') === -1)}"
                      v-on:click.prevent="useSuggestion(impact.amount / 100, DonationTypes.MONTHLY)"
              >Give ${{impact.amount / 100}}/month
              </button>
            </div>
          </div>

        </div>
      </div>
    </magnific-popup-modal>
    </template>
  </inner-layout>

</template>

<script>
  import $ from 'jquery'
  import InnerLayout from '../Parts/inner-layout.vue'
  import HonorNote from '../Donations/honor-note.vue'
  import {mapActions, mapMutations, mapState} from "vuex";
  import {
    roundupAccount
  } from "../../utils/api";
  import SlideUpDown from 'vue-slide-up-down'
  import MagnificPopupModal from '../Parts/MagnificPopupModal'
  import FooterFlow from './footer-flow.vue'
  import BootstrapSelect from "../BootstrapSelect";

  export default {
    name: 'AmountFlow',
    props: {
      isLogin: null,
      charityInfo: null,
      donorDetail: null,
      donationConfig: null,
      donationInfo: null,
      amountSuggest: null,
      allCampaigns: null,
      routeNames: null,
      allDesignations: null,
    },
    components: {
      InnerLayout,
      HonorNote,
      SlideUpDown,
      MagnificPopupModal,
      FooterFlow,
      BootstrapSelect
    },
    mounted() {
      if (this.donationInfo.type === this.DonationTypes.GUEST) {
        this.updateType(this.DonationTypes.ONE_TIME);
      }

      if (this.$route.query && this.$route.query.campaign_id) {
        const selectedCmp = this.allCampaigns.reduce((res, cmp) => cmp.id == this.$route.query.campaign_id ? cmp : res, null)

        if (selectedCmp) {
          this.selectedCampaignId = selectedCmp.id;
        } else {
          this.$emit('flow-error-campaign');
        }

      } else if (this.donationInfo.campaign_id) {
        this.selectedCampaignId = this.donationInfo.campaign_id;
      }
      this.updateRoundUps();
      this.updateAmountSuggest();

      // getGetDonationImpactAmounts(this.charityInfo.charity_id).then(impacts =>
      //   this.donationImpacts = impacts.impacts
      // );

      if (this.donationInfo.designation_id) {
        this.selectedDesignationId = this.donationInfo.designation_id;
      }
    },
    methods: {
      checkImpactType(type, id) {

        let donationImpacts = [];
        switch (type) {
          case this.DonationTypes.MONTHLY:
            donationImpacts = this.donationConfig.donation.giving_amounts.monthly.donation_impacts || [];
            break;
          case this.DonationTypes.ONE_TIME:
          case this.DonationTypes.GUEST:
            donationImpacts = this.donationConfig.donation.giving_amounts.one_time.donation_impacts || [];
            break;
        }

        if (donationImpacts && donationImpacts.length) {
          for (let i = 0; i < donationImpacts.length; i++) {
            if(parseInt(donationImpacts[i].id) === parseInt(id)){
              return true;
            }
          }
        }

        return false
      },
      useSuggestion(amount, type = this.DonationTypes.ONE_TIME) {
        this.$refs.donationSuggestModal.close();
        this.updateType(type);

        setTimeout(() => {
          if (this.amountSuggest.custom_amount.enabled) {
            this.amount_suggest = '';
            this.$nextTick(() => this.amount = amount);
          } else if (Object.values(this.amountSuggest.suggested_amounts).filter(sm => Number(sm.amount) === amount * 100).length) {
            this.$nextTick(() => {
              this.amount_suggest = Number(amount);
            });
          } else {
            this.$emit('flow-error-amount');
          }
        }, 100);
      },
      showSuggest(type) {
        this.$refs.donationSuggestModal.open();
        $("body").find('.popup-tabs-nav a[href="#' + type + '"]').parent("li").click();
      },
      updateType(type) {
        if (type !== this.donationInfo.type) {
          try {
            this.$router.replace({query: {...this.$route.query, 'selected-method': type}});
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
          }

          this.setTypePure(type);
        }
      },
      proceedDonation() {
        this.setCampaignId(this.selectedCampaignId);
        this.setDesignationId(this.selectedDesignationId);

        this.setDonationAmount({
          amount: this.amount,
          charity_id: this.charityInfo.charity_id,
          type: this.donationInfo.type
        });

        this.$emit('done');
      },
      ...mapActions('donation', [
        'setDonationAmount',
      ]),
      ...mapMutations('donation', [
        'setTypePure',
        'setCampaignId',
        'setDesignationId',
      ]),
      updateAmountSuggest(typeUpdated = false) {
        let isSetAmount = false;
        if (typeUpdated === false && this.$route.query && this.$route.query.amount) {
          if (this.amountSuggest.custom_amount.enabled) {
            isSetAmount = true;
            this.amount_suggest = '';
            this.$nextTick(() => this.amount = this.$route.query.amount);
          } else if (Object.values(this.amountSuggest.suggested_amounts).filter(sm => Number(sm.amount) === this.$route.query.amount * 100).length) {
            isSetAmount = true;
            this.amount_suggest = Number(this.$route.query.amount);
          } else {
            this.$emit('flow-error-amount');
          }
        }

        if(typeUpdated === false && this.donationInfo && this.donationInfo.amountSet && this.donationInfo.amount){
          isSetAmount = true;
          if (Object.values(this.amountSuggest.suggested_amounts).filter(sm => Number(sm.amount) === this.donationInfo.amount*100).length) {
            this.amount_suggest = Number(this.donationInfo.amount);
          }
          else{
            this.amount_suggest = '';
            this.$nextTick(() => this.amount = this.donationInfo.amount);
          }
        }

        if (isSetAmount === false) {
          const tmpAmounts = this.amountSuggest.suggested_amounts.filter(item => item.default);
          if (tmpAmounts.length > 0) {
            this.amount_suggest = tmpAmounts[0].amount / 100;
          }
        }
      },
      startRoundUps() {
        this.$emit('roundups-next');
        // if (!this.isLogin) {
        //   this.$tracking.track(this.$tracking.EVENTS.SCREEN_TRANSITION, {
        //     'from': 'get_started',
        //     'to': 'login_register',
        //   });
        //   this.isStartRoundUps = true;
        //   this.$parent.$emit('open-login-modal', 'register');
        //   $('.register-tab').click();
        // } else {
        //
        //   this.$emit('done');
        // }
      },
      updateRoundUps() {
        if (this.isLogin && this.donationInfo.type === this.DonationTypes.ROUNDUP) {
          roundupAccount.getAllRoundupAccounts().then(accounts => this.roundUpAccounts = accounts);
        } else {
          this.roundUpAccounts = false;
        }
      }
    },
    computed: {
      sortedCampaigns() {
        return [...this.allCampaigns].sort((cmp1, cmp2) => {
          return cmp1.name.localeCompare(cmp2.name)
        });
      },
      ...mapState({
        allCampaignsArr: state => state.campaigns.allCampaigns,
        designationsLastUpdate: state => state.designations.lastUpdate,
      }),
      selectedCampaign() {
        if (this.allCampaigns.length) {
          return this.allCampaigns.reduce((res, cmp) =>
            parseInt(cmp.id) === parseInt(this.selectedCampaignId) ? cmp : res, null)
        }
        return null;
      },
      suggested() {
        return this.amountSuggest.suggested_amounts.map(amount => amount.amount / 100);
      },
      donationImpactsFiltered() {
        let donationImpacts = [];
        switch (this.donationInfo.type) {
          case this.DonationTypes.MONTHLY:
            donationImpacts = this.donationConfig.donation.giving_amounts.monthly.donation_impacts || [];
            break;
          case this.DonationTypes.ONE_TIME:
          case this.DonationTypes.GUEST:
            donationImpacts = this.donationConfig.donation.giving_amounts.one_time.donation_impacts || [];
            break;
        }

        return donationImpacts;
      },
      amountImpact() {
        if (this.donationImpactsFiltered.length && this.amount) {
          return this.donationImpactsFiltered.reduce((res, item) => parseInt(item.amount) === Math.round(this.amount * 100) ? item : res, false)
        }
        return false;
      },
    },
    watch: {
      'donationInfo.type'() {
        this.updateAmountSuggest(true);
        this.updateRoundUps();
      },
      'isLogin'() {
        if (this.isStartRoundUps) {
          this.$emit('done');
        } else {
          this.updateRoundUps();
        }
      },
      'amount_suggest'(newval) {
        this.amount = newval;
        if (newval === '') {
          this.$nextTick(() => this.$refs.amount.focus());
        }
      },
    },
    data() {
      return {

        // donationImpacts: [],
        selectedDesignationId: 0,

        roundUpAccounts: false,
        isStartRoundUps: false,
        amount_suggest: 0,
        amount: 50,
        selectedCampaignId: 0,
        isAnimation: false,
      };
    }
  }
</script>
