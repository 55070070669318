<template>
  <inner-layout :charity-info="charityInfo"
                :cover-photo="(selectedCampaign && selectedCampaign.id && selectedCampaign.image)?selectedCampaign.image:''"
  >
    <register-screen
        :is-flow="true"
        v-on:update-donor-details="$parent.$emit('update-donor-details')"
        v-on:open-login-only-modal="openLoginOnlyModal"
        v-on:back="$emit('back')"
        :allow_guest_checkout="donationConfig.donation.allow_guest_checkout"
        :harness_fields="donationConfig.harness_fields"
        :isLogin="isLogin"
        :charityInfo="charityInfo"
        :donation-config="donationConfig"
        :registerTitle="(donationInfo.type === 'monthly' ? ('$' + $options.filters.formatMoney(donationInfo.amount) + ' Monthly Gift') : ('$' + $options.filters.formatMoney(donationInfo.amount) + ' Donation'))"
    ></register-screen>
  </inner-layout>
</template>

<script>
  import InnerLayout from '../Parts/inner-layout.vue'
  import RegisterScreen from '../Donations/register.vue'
  import DonationTypes from '../../store/modules/donation-types'

  export default {
    name: 'RegisterFlow',
    props: {
      isLogin: null,
      charityInfo: null,
      donorDetail: null,
      donationConfig: null,
      donationInfo: null,
      selectedCampaign: null,
    },
    components: {
      RegisterScreen,
      InnerLayout,
    },
    methods: {
      openLoginOnlyModal(e) {
        this.$parent.$emit('open-login-only-modal', e)
      }
    },
    watch: {
      'donationInfo.type'() {
        if (this.donationInfo.type === DonationTypes.GUEST) {
          this.$emit('done');
        }
      },
      'isLogin'() {
        if (this.isLogin) {
          this.$emit('done');
        }
      },
    }
  }
</script>