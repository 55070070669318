<template>
  <inner-layout :charity-info="charityInfo"
                :cover-photo="(selectedCampaign && selectedCampaign.id && selectedCampaign.image)?selectedCampaign.image:''"
  >

    <div class="order-confirmation-page">
      <div class="welcome-text">
        <tick></tick>
      </div>
      <h2 class="margin-top-30">Thank You {{isLogin && donorDetail ? donorDetail.first_name :
        donationInfo.first_name}}!</h2>
      <p v-if="donationConfig.donation.thank_you.message">{{donationConfig.donation.thank_you.message}}</p>
      <p v-else>We really appreciate your donation of
        <span class="secondary-color fw-600">${{(donationInfo.donatedAmount + processingFees) | formatMoney}}</span>
        <span v-if="selectedCampaign">
        to<br>
        <a
          :href="`${harnessGivingURL}campaigns/${selectedCampaign.id}`"
           class="fw-600"
           target="_blank">{{selectedCampaign.name}}</a>
        </span>!
      </p>
      <div v-if="donationConfig.donation.thank_you.actions && donationConfig.donation.thank_you.actions.length">
        <h4 style="padding-bottom:5px; font-weight:600;">What would you like to do next?</h4>

        <span v-if="donationConfig.donation.thank_you.actions.indexOf('complete_profile') !== -1">
          <a :href="`${harnessGivingURL}account/profile`"
                       class="button full-width button-sliding-icon ripple-effect margin-top-10 margin-left-5 margin-right-5"
          >Complete Profile <i><font-awesome-icon :icon="['far', 'user-circle']"/></i>
          </a>
        </span>
        <span v-if="donationConfig.donation.thank_you.actions.indexOf('view_receipt') !== -1">
          <router-link :to="{name: 'DonationReceipt'}"
                       class="button full-width button-sliding-icon ripple-effect margin-top-10 margin-left-5 margin-right-5"
          >View Receipt <i><font-awesome-icon :icon="['far', 'print']"/></i>
          </router-link>
          </span>

        <span v-if="donationConfig.donation.thank_you.actions.indexOf('donation_history') !== -1">
          <a :href="`${harnessGivingURL}account/donations`"
                       class="button full-width button-sliding-icon ripple-effect margin-top-10 margin-left-5 margin-right-5"
          >View Donations <i><font-awesome-icon :icon="['far', 'chart-bar']"/></i>
          </a>
        </span>
        <template v-if="donationConfig.donation.thank_you.custom_links
        && donationConfig.donation.thank_you.custom_links.length">

          <span v-for="(link, index) in donationConfig.donation.thank_you.custom_links"
                :key="index"
          >
            <a :href="link.url"
               class="button full-width button-sliding-icon ripple-effect margin-top-10 margin-left-5 margin-right-5"
            >{{link.title}}
            </a>
          </span>

        </template>


        <div
           v-if="donationConfig.donation.thank_you.actions.indexOf('share_facebook') !== -1 || donationConfig.donation.thank_you.actions.indexOf('share_twitter') !== -1"
           class="freelancer-socials margin-top-15 margin-bottom-10">
          <ul>
            <li v-if="donationConfig.donation.thank_you.actions.indexOf('share_facebook') !== -1">
              <a :href="`https://www.facebook.com/sharer/sharer.php?u=${pageLink}`"
                 v-tippy="{ placement : 'top'}"
                 target="_blank"
                 title="Share on Facebook">
                <font-awesome-icon :icon="['fab', 'facebook-square']"/>
              </a>
            </li>
            <li v-if="donationConfig.donation.thank_you.actions.indexOf('share_twitter') !== -1">
              <a :href="`https://twitter.com/intent/tweet?text=${shareText}`"
                 target="_blank"
                 v-tippy="{ placement : 'top'}" title="Share on Twitter">
                <font-awesome-icon :icon="['fab', 'twitter']"/>
              </a>
            </li>
          </ul>
        </div>

      </div>


      <p style="font-size:12px; padding-top:10px;">
        <a v-if="$root.inIframe" href="#" v-on:click.prevent="closeModal">I'm all set! Take me back to the website</a>
        <a v-else-if="charityInfo && charityInfo.website"
           :href="charityInfo.website | correctLink"
        >I'm all set! Take me back to the website</a>
        <router-link v-else :to="{name: 'Donate', query: {new: 'new'}}">I'm all set! Take me back to the website
        </router-link>

      </p>
    </div>
  </inner-layout>


</template>

<script>
  import {getHarnessGivingURL} from '../../utils/utils'
  import InnerLayout from '../Parts/inner-layout.vue'
  import Tick from '../Parts/tick.vue'
  import {EventBus} from '../../utils/event-bus.js';

  export default {
    name: 'ThanksFlow',
    props: {
      isLogin: null,
      charityInfo: null,
      donorDetail: null,
      donationConfig: null,
      donationInfo: null,
      selectedCampaign: null,
    },
    components: {
      InnerLayout,
      Tick,
    },
    mounted() {
      const params = {
        amount: this.donationInfo.amount + (this.donationInfo.is_cover_fee ? (this.donationInfo.processingFees / 100) : 0),
        type: this.donationInfo.type,
      };

      if (this.donationInfo.campaign_id) {
        params['campaign_id'] = this.donationInfo.campaign_id;
      }

      if (this.donationInfo.flow_id) {
        params['checkout_id'] = this.donationInfo.flow_id;
      }

      this.$router.replace({name: 'DonationConfirmation', query: params});

      EventBus.$emit('show-donor-menu-help');
    },
    methods: {
      closeModal() {
        window.parent.postMessage('close-modal', "*");
      },
    },
    computed: {
      harnessGivingURL() {
        return getHarnessGivingURL()
      },
      shareText() {
        return encodeURIComponent('Thank you so much for your donation to ' +
          (this.charityInfo ? this.charityInfo.charity_name : '') +
          ' ' + (this.donorDetail ? this.donorDetail.first_name : '') +
          '! We are truly grateful for your generosity and wanted to thank you for being a supporter of our cause!');
      },
      processingFees() {
        return this.donationInfo.is_cover_fee ? (this.donationInfo.processingFees / 100) : 0;
      }
    },
    data() {
      return {
        pageLink: encodeURIComponent(window.location.href)
      };
    },
  }
</script>
