<template>
  <div class="donation-wrap" v-if="donationConfig">
    <div v-if="donationFlowRestriction && screen === FlowScreens.AMOUNT"
         class="notification error margin-bottom-0 padding-bottom-15 padding-top-15 donationFlowRestriction-error">
      <p v-if="donationFlowRestriction === 'amount'"><b>Please choose another amount </b> - This specific donation form
        only allows for specific amounts</p>
      <p v-else-if="donationFlowRestriction === 'flow-id'"><b>Donation Form Unavailable</b> - We're sorry but this
        specific link seems to be incorrect</p>
      <p v-else-if="donationFlowRestriction === 'selected-method'"><b>Donation Method Unavailable</b> - Please select
        from one of our other donation methods</p>
      <p v-else-if="donationFlowRestriction === 'campaign_id'"><b>Campaign Unavailable </b> - Please select from one of
        our other campaigns</p>
    </div>


    <AmountFlow v-if="screen === FlowScreens.AMOUNT"
                :isLogin="isLogin"
                :routeNames="routeNames"
                :donationConfig="donationConfig"
                :donationInfo="donationInfo"
                :charityInfo="charityInfo"
                :donorDetail="donorDetail"
                :amountSuggest="amountSuggest"
                :all-campaigns="allCampaigns"
                :all-designations="allDesignations"
                v-on:flow-error-campaign="donationFlowRestriction = 'campaign_id'"
                v-on:flow-error-amount="donationFlowRestriction = 'amount'"
                v-on:done="amountDone"
                v-on:roundups-next="screen = FlowScreens.ROUND_UPS_HOW_IT_WORKS"
    ></AmountFlow>

    <SmartAskFlow v-if="screen === FlowScreens.SMART_ASK"
                  :isLogin="isLogin"
                  :donationConfig="donationConfig"
                  :donationInfo="donationInfo"
                  :charityInfo="charityInfo"
                  :donorDetail="donorDetail"
                  :selectedCampaign="selectedCampaign"
                  v-on:done="smartAskDone"
                  v-on:back="goBack(FlowScreens.AMOUNT)"
    ></SmartAskFlow>

    <RegisterFlow v-if="screen === FlowScreens.REGISTER"
                  :isLogin="isLogin"
                  :donationConfig="donationConfig"
                  :donationInfo="donationInfo"
                  :charityInfo="charityInfo"
                  :donorDetail="donorDetail"
                  :selectedCampaign="selectedCampaign"
                  v-on:done="registerDone"
                  v-on:back="goBack(FlowScreens.SMART_ASK)"
    ></RegisterFlow>

    <PaymentFlow v-if="screen === FlowScreens.PAYMENT"
                 :isLogin="isLogin"
                 :donationConfig="donationConfig"
                 :donationInfo="donationInfo"
                 :charityInfo="charityInfo"
                 :donorDetail="donorDetail"
                 :selectedCampaign="selectedCampaign"
                 :allCampaigns="allCampaigns"
                 v-on:done="paymentDone"
                 v-on:back="goBack(FlowScreens.CUSTOM_FIELDS)"
    ></PaymentFlow>

    <CustomFieldsFlow v-if="screen === FlowScreens.CUSTOM_FIELDS"
                      :isLogin="isLogin"
                      :donationConfig="donationConfig"
                      :donationInfo="donationInfo"
                      :charityInfo="charityInfo"
                      :donorDetail="donorDetail"
                      v-on:done="customFieldsDone"
                      v-on:back="goBack(FlowScreens.SMART_ASK)"
    ></CustomFieldsFlow>

    <ThanksFlow v-if="screen === FlowScreens.THANKS_PAGE"
                :isLogin="isLogin"
                :donationConfig="donationConfig"
                :donationInfo="donationInfo"
                :charityInfo="charityInfo"
                :donorDetail="donorDetail"
                :selectedCampaign="selectedCampaign"
    ></ThanksFlow>
    <RoundupsConnectCard v-if="screen === FlowScreens.ROUND_UPS"
                         :flowId="donationInfo.flow_id"
                         :donationConfig="donationConfig"
                         :flowCampaigns="allCampaigns"
                         :flowCampaignId="donationInfo.campaign_id"
                         :isLogin="isLogin"
                         :charityInfo="charityInfo"
                         :donorDetail="donorDetail"
                         v-on:back="goBack(FlowScreens.ROUND_UPS_HOW_IT_WORKS)"
    ></RoundupsConnectCard>
    <RoundupsHowItWorks v-if="screen === FlowScreens.ROUND_UPS_HOW_IT_WORKS"
                         :flowId="donationInfo.flow_id"
                         :donationConfig="donationConfig"
                          :selectedCampaign="selectedCampaign"
                         :flowCampaigns="allCampaigns"
                         :flowCampaignId="donationInfo.campaign_id"
                         :isLogin="isLogin"
                         :charityInfo="charityInfo"
                         :donorDetail="donorDetail"
                        v-on:done="amountDone"
                        v-on:back="goBack(FlowScreens.AMOUNT)"

    ></RoundupsHowItWorks>

    <more-info-modal :charityInfo="charityInfo"
                     ref="moreInfoModal"
    ></more-info-modal>
  </div>
</template>

<script>
  import {getDonationFlow} from '../../utils/api'
  import MoreInfoModal from '../Modals/more-info.vue'
  import AmountFlow from './amount-flow'
  import SmartAskFlow from './smart-ask-flow'
  import RegisterFlow from './register-flow'
  import CustomFieldsFlow from './custom-fields-flow'
  import PaymentFlow from './payment-flow'
  import ThanksFlow from './thanks-flow'
  import configMixin from './config-mixin'
  import RoundupsConnectCard from '../Donations/roundups-connect-card'
  import RoundupsHowItWorks from '../Donations/roundups-how-it-works'
  import {mapActions, mapState, mapMutations} from 'vuex'

  const FlowScreens = Object.freeze({
    AMOUNT: 'amount',
    SMART_ASK: 'smart-ask',
    REGISTER: 'register',
    PAYMENT: 'payment',
    CUSTOM_FIELDS: 'custom-fields',
    THANKS_PAGE: 'thanks-page',
    ROUND_UPS: 'round-ups',
    ROUND_UPS_HOW_IT_WORKS: 'roundups-how-it-works',
  });

  export default {
    name: 'CustomDonation',
    props: {
      isLogin: null,
      charityInfo: null,
      donorDetail: null,
      routeNames: null,
      flowConfig: null
    },
    mixins: [configMixin],
    created() {
      this.FlowScreens = FlowScreens;
      if (this.$route.query && 'auth' in this.$route.query) {
        setTimeout(() => {
          this.$emit('open-login-modal');
        }, 600);
      }
    },
    components: {
      MoreInfoModal,
      AmountFlow,
      SmartAskFlow,
      RegisterFlow,
      PaymentFlow,
      CustomFieldsFlow,
      ThanksFlow,
      RoundupsConnectCard,
      RoundupsHowItWorks,
    },
    metaInfo() {
      return {
        meta: [
          {
            'property': 'og:title',
            'content': 'Support ' + (this.charityInfo ? this.charityInfo.charity_name : ''),
          },
          {
            'property': 'og:description',
            'content': 'Join me in supporting ' + (this.charityInfo ? this.charityInfo.charity_name : '') + '! Use this link to give: ' + this.pageLink,
          },
          {
            'property': 'og:image',
            'content': (this.charityInfo ? this.charityInfo.cover_photo : ''),
          },
          {
            'property': 'twitter:card',
            'content': 'summary',
          },
          {
            'property': 'twitter:title',
            'content': 'Support ' + (this.charityInfo ? this.charityInfo.charity_name : ''),
          },
          {
            'property': 'twitter:description',
            'content': 'Join me in supporting ' + (this.charityInfo ? this.charityInfo.charity_name : '') + '! Use this link to give: ' + this.pageLink,
          },
          {
            'property': 'twitter:image',
            'content': (this.charityInfo ? this.charityInfo.cover_photo : ''),
          },
        ]
      }
    },
    mounted() {
      this.initDonationFlow();

      this.updateDesignations({charity_id: this.charityInfo.charity_id});

      if ((this.$route.name === 'DonationConfirmation' || this.$route.name === 'RoundupsConfirmation')
        && this.screen === 'amount') {
        this.$router.push({name: 'Donate'});
      }
    },
    beforeDestroy() {
      if (this.draftInterval) {
        this.upDraft();
        clearInterval(this.draftInterval);
      }
    },
    methods: {
      ...mapActions('campaigns', [
        'updateCampaigns',
      ]),
      ...mapActions('designations', [
        'updateDesignations',
      ]),
      ...mapActions('donation', [
        'setDonationAmount',
      ]),
      ...mapMutations('donation', [
        'setFlowId',
        'setCampaignId',
        'setDesignationId',
        'monthlyAnswer',
        'setTypePure',
        'resetState',
        'setState',
        'setScreen'
      ]),
      ...mapActions('donationDraft', [
        'saveDraft',
        'resetDraft',
      ]),
      upDraft() {
        if ([FlowScreens.AMOUNT].indexOf(this.screen) !== -1) {
          return;
        }

        if (this.donationInfo.isDone) {
          this.resetDraft();
          if (this.draftInterval) {
            clearInterval(this.draftInterval);
          }

        } else {
          if (this.donationInfo.type !== this.DonationTypes.ROUNDUP) {
            this.saveDraft();
          }
        }
      },
      initDonationFlow(useDefaultFlow = false) {
        let query = Object.assign({}, this.$route.query);

        if ('new' in query) {
          delete query.new;
          this.$router.replace({query});
          this.donationFlowRestriction = false;
        }

        if ('continue-donation' in this.$route.query
          && this.$store.state.donationDraft.draft
          && this.draftState.draft.screen
        ) {
          this.screen = this.draftState.draft.screen;
        } else {
          this.resetState();
        }

        let flowId = useDefaultFlow === false && this.$route.query && (this.$route.query['flow-id'] || this.$route.query['checkout']) || this.charityInfo.default_flow_id || 0;

        this.setFlowId({flow_id: flowId});

        Promise.all([
          getDonationFlow(this.charityInfo.charity_id, flowId),
          this.updateCampaigns({charity_id: this.charityInfo.charity_id})
        ])// eslint-disable-next-line
        .then(([{flow}, tmp]) => {
          flow = this.normalizeFlow(flow);

          //save flow config
          this.setConfig(flow);

          const formConfig = JSON.parse(JSON.stringify(flow.properties));
          if ('continue-donation' in this.$route.query && this.$store.state.donationDraft.draft) {

            this.setState(this.draftState.draft);
          } else {

            this.resetDraft();

            //set default campaign
            if (formConfig.donation.campaigns.default) {
              this.setCampaignId(formConfig.donation.campaigns.default);
            }

            //set default designation
            if (formConfig.donation.designations.default) {
              this.setDesignationId(formConfig.donation.designations.default);
            }

            let selectedMethod = formConfig.donation.allowed_methods.indexOf(formConfig.donation.default_method) !== -1
              ? formConfig.donation.default_method : formConfig.donation.allowed_methods[0];

            if (this.$route.query['selected-method']) {
              if (formConfig.donation.allowed_methods.indexOf(this.$route.query['selected-method']) !== -1) {
                selectedMethod = this.$route.query['selected-method'];
              } else {
                this.donationFlowRestriction = 'selected-method';
              }
            }
            //initial lead tracking
            if (selectedMethod === this.donationInfo.type) {
              this.$tracking.track(this.$tracking.EVENTS.LEAD, {
                'content_category': this.donationInfo.type,
              });
            }

            this.setTypePure(selectedMethod);

            //if one amount && 1||0 campaigns && 1||0 desinations && one allowed method
            if (this.donationInfo.type !== this.DonationTypes.ROUNDUP
              && formConfig.donation.allowed_methods.length === 1
              && this.amountSuggest.custom_amount.enabled === false && this.amountSuggest.suggested_amounts.length === 1
              && String(formConfig.donation.campaigns.options).toLowerCase() !== 'all' && formConfig.donation.campaigns.options.length <= 1
              && String(formConfig.donation.designations.options).toLowerCase() !== 'all' && formConfig.donation.designations.options.length <= 1
            ) {

              if (formConfig.donation.campaigns.options.length === 1) {
                let [cmpId] = formConfig.donation.campaigns.options[0];
                this.setCampaignId(cmpId);
              }

              if (formConfig.donation.designations.options.length === 1) {
                let [dId] = formConfig.donation.designations.options[0];
                this.setDesignationId(dId);
              }

              this.setDonationAmount({
                amount: this.amountSuggest.suggested_amounts[0].amount / 100,
                charity_id: this.charityInfo.charity_id,
                type: this.donationInfo.type
              });

              this.amountDone();

            } else if (this.isLogin && this.$route.name === 'RoundupsConnectCard' && this.$route.query['fix-account']) {
              this.setTypePure(this.DonationTypes.ROUNDUP);
              this.screen = FlowScreens.ROUND_UPS;
            }
          }

          this.$root.donationScreen = this.screen;
          this.$root.donationType = this.donationInfo.type;

          this.draftInterval = setInterval(this.upDraft, 500);
        })
          .catch(() => {
            if (!useDefaultFlow) {
              //remove wrong parameter
              // let queryParams = {...this.$route.query};
              // delete queryParams['flow-id'];
              // this.$router.replace({query: queryParams});
              this.donationFlowRestriction = 'flow-id';
              this.initDonationFlow(true);
            } else {
              // eslint-disable-next-line no-console
              console.warn('can not load flow ID');
            }
          });
      },
      paymentDone() {
        this.upDraft();
        this.resetDraft();

        const trackingInfo = {
          'content_name': this.donationInfo.type,
          'currency': 'USD',
          'value': parseInt((this.donationInfo.amount + (this.donationInfo.is_cover_fee ? (this.donationInfo.processingFees / 100) : 0)) * 100),
        };


        if ([this.DonationTypes.ONE_TIME, this.DonationTypes.GUEST].indexOf(this.donationInfo.type) !== -1) {
          this.$tracking.track(this.$tracking.EVENTS.PURCHASE, trackingInfo);
        } else if (this.donationInfo.type === this.DonationTypes.MONTHLY) {
          this.$tracking.track(this.$tracking.EVENTS.SUBSCRIBE, trackingInfo);
        }

        this.screen = FlowScreens.THANKS_PAGE;
      },
      customFieldsDone() {
        this.upDraft();
        this.screen = FlowScreens.PAYMENT;
      },
      registerDone() {
        this.upDraft();

        if (this.donationConfig.custom_fields && this.donationConfig.custom_fields.fields.length > 0 ) {
          this.screen = FlowScreens.CUSTOM_FIELDS;
        } else {
          this.customFieldsDone();
        }
      },
      smartAskDone() {
        this.upDraft();
        if (!this.isLogin) {
          this.screen = FlowScreens.REGISTER;
        } else {
          this.registerDone();
        }
      },
      goBack(screen) {
        this.upDraft();
        if (screen === FlowScreens.CUSTOM_FIELDS &&
            !(this.donationConfig.custom_fields && this.donationConfig.custom_fields.fields.length > 0)
        ) {
          return this.goBack(FlowScreens.SMART_ASK);
        }

        if (screen === FlowScreens.SMART_ASK && this.donationInfo.type === this.DonationTypes.MONTHLY) {
          screen = FlowScreens.AMOUNT;
        }
        this.screen = screen;
      },
      amountDone() {
        if (this.donationInfo.type !== this.DonationTypes.ROUNDUP) {
          this.$tracking.track(this.$tracking.EVENTS.INITIATE_CHECKOUT, {
            'content_category': this.donationInfo.type,
            'value': parseInt(this.donationInfo.amount * 100),
          });
        }

        if (this.donationInfo.type === this.DonationTypes.ROUNDUP) {
          this.screen = FlowScreens.ROUND_UPS;
        } else if (this.donationInfo.type === this.DonationTypes.ONE_TIME
          && this.donationConfig.donation.smart_ask.enabled
          && (this.donationConfig.donation.smart_ask.threshold.max_cents > (this.donationInfo.amount * 100) &&
            this.donationConfig.donation.smart_ask.threshold.min_cents < (this.donationInfo.amount * 100))
        ) {
          this.screen = FlowScreens.SMART_ASK;
        } else {
          this.smartAskDone();
        }
      },
      resetFlow() {
        let query = Object.assign({}, this.$route.query);

        if ('new' in query) {
          delete query.new;
          this.$router.replace({query});

          this.resetState();
          this.screen = FlowScreens.AMOUNT;
          this.initDonationFlow();
        }
      },
      // trackScreen(old = '') {
      //   console.log(this.screen, old);
      // },
    },
    computed: {
      ...mapState({
        donationInfo: state => state.donation,
        campaignsLastUpdate: state => state.campaigns.lastUpdate,
        draftState: state => state.donationDraft,
      }),
      pageLink() {
        return window.location.origin + this.$router.resolve('/').href;
      },
      selectedCampaign() {
        if (this.allCampaigns.length) {
          return this.allCampaigns.reduce((res, cmp) => parseInt(cmp.id) === parseInt(this.donationInfo.campaign_id) ? cmp : res, null)
        }
        return null;
      },
      allCampaigns() {
        let allCampaigns = this.$store.getters['campaigns/allCampaignsWithDefault'](this.charityInfo);

        if (this.donationConfig.donation.campaigns.selection_required) {
          allCampaigns.shift()
        }

        if (typeof this.donationConfig.donation.campaigns.options === 'string'
          && this.donationConfig.donation.campaigns.options.toLowerCase() === 'all') {
          return allCampaigns;
        }

        if (!this.donationConfig.donation.campaigns.options || this.donationConfig.donation.campaigns.options.length === 0) {
          return [];
        }

        //keep order
        return this.donationConfig.donation.campaigns.options.map(cmp =>
          allCampaigns.reduce((res, cmp2) => Number(cmp2.id) === Number(cmp.id) ? cmp2 : res, null)
        ).filter(Boolean);
      },
      allDesignations() {
        let allDesignations = this.$store.state.designations.allDesignations;

        if (typeof this.donationConfig.donation.designations.options === 'string'
          && this.donationConfig.donation.designations.options.toLowerCase() === 'all') {
          return allDesignations;
        }

        if (!this.donationConfig.donation.designations.options || this.donationConfig.donation.designations.options.length === 0) {
          return [];
        }

        //keep order
        return this.donationConfig.donation.designations.options.map(cmp =>
          allDesignations.reduce((res, cmp2) => Number(cmp2.id) === Number(cmp.id) ? cmp2 : res, null)
        ).filter(Boolean);
      },
      amountSuggest() {
        try {
          if (this.donationInfo.type === this.DonationTypes.ONE_TIME) {
            return this.donationConfig.donation.giving_amounts.one_time;
          } else {
            return this.donationConfig.donation.giving_amounts.monthly;
          }
        } catch (e) {
          return {
            suggested_amounts: [1000, 2000, 5000, 10000],
            custom_amount: {"enabled": false}
          };//fallback;
        }
      },
    },
    watch: {
      '$route'() {
        this.donationFlowRestriction = false;
        this.resetFlow();
      },
      'donationInfo.type'() {
        this.$root.donationType = this.donationInfo.type;

        this.$tracking.track(this.$tracking.EVENTS.LEAD, {
          'content_category': this.donationInfo.type,
        });
      },
      'screen'() {
        this.setScreen(this.screen);
        this.$root.donationScreen = this.screen;
      },
    },
    data() {
      return {
        draftInterval: null,
        screen: FlowScreens.AMOUNT,
        donationFlowRestriction: false, //amount, flow-id, selected-method, campaign_id
      };
    }
  }
</script>
